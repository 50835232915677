@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(/src/index.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Rubik&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(/src/index.css);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alert-warning {
  color: #283e59 !important;
  background-color: #f6c343 !important;
  border-color: #f6c343 !important;
}

:root {
  --sidebar-width: 13.75rem;
}
.button-contained {
  font-family: "Lato", serif;
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 700;
}

.button-contained-2 {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
  border-radius: 0.5rem;
}

.disabled-contained {
  background: rgba(110, 132, 163, 0.12);
  color: rgba(110, 132, 163, 0.26);
}

.disabled-contained-2 {
  background: #b7b6bf;
  color: #3a3940;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.secondary-outlined {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #B7B6BF;
  font-weight: 700;
}

.primary-outlined {
  background-color: transparent;
  border-radius: 8px;
  border: 2px solid #2C7BE5;
  color: #2C7BE5;
  font-weight: 700;
}

.primary-link {
  background-color: transparent;
  font-weight: 700;
  color: #0050c3;
}

.primary-contained {
  background-color: #0050c3;
  border: 2px solid #0050c3;
  color: #fff;
}

.new-primary-contained {
  background-color: #1C70E9;
  border: 2px solid #1C70E9;
  color: #fff;
}

.primary-contained:hover {
  background-color: #002d8c;
}

.primary-contained[disabled] {
  background-color: #B7B6BF;
  border: 1px solid #B7B6BF;
  color: #525059;
}

.primary-contained[disabled]:active {
  background-color: #B7B6BF;
}



.primary-outlined:hover {
  background-color: #fff;
}

.primary-contained:active {
  background-color: #00145f;
}


.secondary-outlined:hover {
  background-color: #eae9ed;

}

.secondary-outlined:active {
  background-color: #d3d2d9;
}

.primary-icon {
  border-radius: 8px;
  border: 1px solid #D3D2D9;
  background: #FFF;
  height: 36px;
}

.primary-icon:hover {
  background: #EAE9ED;
}

.primary-icon:active {
  background: #D3D2D9;
}

.primary-icon:focus-visible {
  border: 1px solid #0050C3;
}

.button-container {
  position: relative;
  display: flex;
}

.tooltip-text {
  visibility: hidden;
  width: 245px;
  height: 60px;
  background-color: #2F2E33;
  color: #C8E6FA;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 11px;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  bottom: -5px;
  border-color: #2F2E33 transparent transparent transparent;
  margin-left: -5px;
  border-width: 5px;
}

.button-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.upload-image {
  border-radius: .375rem;
  width: 64px;
  height: 64px;
  background-size: cover;
  margin: 0 auto;
}
#loading {
  height: 100%;
  background-color: #fffffff5;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 10000;
  flex-flow: column;
  width: 95vw;
  transition: 1s;
}

#loading p {
  margin: 0;
}
#loading p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
  margin-top: -3rem;
}

.loading-title {
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width: 991px) {
  #loading {
    width: 100%;
    height: calc(100% - 62px);
  }

  .loading-title {
    padding-left: 38%;
  }
}
@media (max-width: 768px) {
  #loading {
    height: calc(100% - 52px);
  }
}
#loading.esconde {
  display: none;
}
#loading-symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: 15%;
}
#loading-border {
  animation: expansion 2s infinite linear;
  position: absolute;
  border: 4px solid;
  border-radius: 100px;
}
@keyframes expansion {
  0% {
    width: 4rem;
    height: 4rem;
  }
  50% {
    width: 5rem;
    height: 5rem;
  }
  100% {
    width: 4rem;
    height: 4rem;
  }
}

#page-error{
    flex-flow: column;
}
#page-error h2{
    font-size: 100px;
    color: #2c7be5;
}
#page-error a{
    text-decoration: underline;
    color: #2c7be5;
    font-weight: bold;
}
#register-establishment .btn-secondary{
    width: 100%;
}
#register-establishment .input-group-text{
    font-size: 0.75rem;
}
.type-cards button{
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    font-weight: bold;
    color: #495057;
    background-color: #dae0e5;
    margin-bottom: 0.5rem;
}
.type-cards button img{
    max-width: 1.8rem;
    max-height: 1rem;
    margin-right: 0.5rem;
}

.btn-type{
    width: 100%;
    background: #F1F9F9;
    border: 2px solid #F1F9F9;
    border-radius: 8px;
    font-size: 0.85rem;
    background: #F1F9F9;
    color: #8FA5B2;
    padding: 0 0.35rem !important;
    height: 35px;
}
.btnGroup{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.btnClass{
    width: 100%;
}
.btnClass img{
    width: 25px;
    filter: invert(60%) sepia(26%) saturate(208%) hue-rotate(158deg) brightness(101%) contrast(94%);
}
.btnClass.active .btn-type{
    background-color: #22EE98;
    border-color: #00D97E;
    color: white;
}
.btnClass.active img{ 
    filter: brightness(1000%) !important;
}
.btnGroup .payment-delete{
    position: absolute;
    color: #6e84a3;
    right: 1.5rem;
    cursor: pointer;
}
.btnClass.active .payment-delete{
    color: white !important;
}
.btnGroup .payment-delete:hover{
    color: #e63757;
}
.title-body{
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.category-sale{
    margin-top: 0.5rem;
    border: 1px solid;
    background-color: white;
    border-color: #edf2f9;
    color: #12263f;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}
.category-header{
    background-color: #f9fbfd;
    color: #6e84a3;
    border-bottom: 1px solid #edf2f9;
    display: flex;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    align-items: center;
}
.category-header .order-buttons{
    display: flex;
    justify-content: space-between;
}
.category-header .order-buttons .btn{
    width: 200px;
}
.category-header .btn.btn-outline-secondary{
    color: #6e84a3 ;
    border-color: #6e84a3 !important;
}
.category-header .btn.btn-outline-secondary:hover{
    color: white !important;
}
.category-header .order-datetime{
    display: flex;
    align-items: center;
}
.category-header .order-date{
    padding: 0.25rem 0.5rem;
    border-radius: 8px;
    background: lightslategray;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.category-header .order-date span:last-child{
    padding-left: 0.25rem;
}
.category-header .order-time{
    margin-left: 1rem;
}
.category-body .product-detail-row:first-child{
    border: none;
}
.category-body .product-detail-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-top: 1px solid #edf2f9;
}
.category-body .product-detail-row .product-detail-row-img {
    background-size: cover !important;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    background: grey;
}
.category-body .product-detail-row .item-desc{
    text-align: right;
}
.category-body .product-detail-row .item-desc span:first-child{
    font-weight: bold;
}
.category-body .product-detail-row .item-desc span:last-child{
    color: #95aac9;
}
.category-body .product-detail-row .item-desc span{
    display: block;
}
#event-report .card .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  border-left: none !important;
}
#event-report .subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#event-report td a {
  color: #0050c3;
  text-decoration: underline;
}
.type-nav {
  border-bottom: 3px solid #95aac9;
  text-align: center;
  cursor: pointer;
}
.type-nav:hover,
.type-nav.active {
  border-color: #0050c3;
}
.type-nav button {
  border: 0;
  padding-bottom: 0.5rem;
  font-weight: normal !important;
  background-color: transparent;
  color: #95aac9;
  align-items: center !important;
}
.type-nav:hover button,
.type-nav.active button {
  color: #0050c3;
}

#button {
  /* color: white; */
  width: 100%;
  height: 48px;
  display: inline-block;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px "Roboto";
  padding: 0px 5px;
}

.btn-one {
  background-color: #95aac9 !important;
  color: white;
  border: 1px solid #95aac9;
}

.btn-one:hover {
  background-color: #600ea3 !important;
  color: #fff !important;
  border: 1px solid #7228bf;
  box-shadow: 0 0.5px 0px -0.5px;
  transform: translateY(1px);
}

.btn-one:focus:not(:disabled) {
  background: #d3d3d3 !important;
  cursor: default !important;
  border: 1px solid #dee2e6;
  color: #6e84a3;
}

.btn-two {
  color: #ced4da;
  background-color: transparent;
  background-image: none;
  border: 2px solid #ced4da !important;
  width: 100%;
}

.btn-two:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0.5px 0px -0.5px;
  transform: translateY(1px);
}

.btn-two:focus:not(:disabled) {
  background: #d3d3d3 !important;
  cursor: default !important;
  border: 1px solid #dee2e6;
  color: #6e84a3;
}

.btn-three {
  color: #fff;
  background-color: #6e84a3;
  border: 1px solid #6e84a3;
  width: 100%;
}

.btn-three:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: 0 0.5px 0px -0.5px;
  transform: translateY(1px);
}

.btn-three:focus:not(:disabled) {
  background: #d3d3d3 !important;
  cursor: default !important;
  border: 1px solid #dee2e6;
  color: #6e84a3;
}

.btn-four {
  background-color: transparent;
  border: 1px solid #2c7be5;
  color: #2c7be5;
  height: calc(1.5em + 1rem + 2px) !important;
}

.btn-four:hover {
  background-color: #2c7be5;
  color: #fff;
  box-shadow: 0 0.5px 0px -0.5px;
  transform: translateY(1px);
}

.btn-four:focus:not(:disabled) {
  background: #d3d3d3 !important;
  cursor: default !important;
  border: 1px solid #dee2e6;
  color: #6e84a3;
}

.btn-five {
  background-color: #95aac9 !important;
  color: white;
  border: 1px solid #95aac9;
  height: calc(1.5em + 1rem + 2px) !important;
}

.btn-five:hover {
  background-color: #600ea3 !important;
  color: #fff !important;
  border: 1px solid #7228bf;
  box-shadow: 0 0.5px 0px -0.5px;
  transform: translateY(1px);
}

.btn-five:focus:not(:disabled) {
  background: #d3d3d3 !important;
  cursor: default !important;
  border: 1px solid #dee2e6;
  color: #6e84a3;
}

#venda-detalhe{
    font-size: 14px;
    color: #4f6c7c;
    background-color: rgba(0, 0, 0 ,0.75);
    z-index: 200000;
}
.client-data{
    width: 100%;
}
.client-data span{
    display: block;
}

.card-name:first-letter {
    text-transform:capitalize;
}
#empty-data{
    text-align: center;
    background: transparent;
    color: #95aac9;
}
/*BUG FIX*/
.table tbody #empty-data{
    position: absolute;
    background: white;
}
.caixaTop:not(:first-child):not(:last-child) {
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
}
.sale-action{
  cursor: pointer;
}
.sale-action svg{
  font-size: 18px;
  margin-left: 15px;
  margin-top: 3px;
  color: #8fa5b2;
}
.sale-action:hover svg{
  color: #2c7be5;
}

.card-header select, .card-header input{
  height: 48px;
}
.card-header row{
  display: flex;
  justify-content: space-between;
}
.card-header .form-control{
  border:none;
  color: #4F6C7C;;
}
.card-header .form-control:hover {
  border-color: none;
}
.card-header .col-sm{
  border-left: 2px solid #edf2f9;
}
.card-header .col-sm:first-child{
  border-left: none;
}
.btn-actions{
  text-align: right;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem !important;
}
#table-sales .badge{
  min-width: 65px ;
}
#table-sales .payment-none{
  color: #95aac9;
}
.rounded-tag {
    background-color: #95aac9;
    border-radius: 25px;
    font-weight: bolder;
    color: #fff;
    text-align: center;
    transition: all 0.3s;
    font-size: 12px;
    padding: 5px;
    cursor: pointer;
}

.card-command {
    min-height: 126px;
    cursor: pointer;
    transition: all 0.3s;
}

.card-command:hover {
    box-shadow: 0 .300rem .60rem rgba(0,0,0,.25)!important;
}

.tag-active {
    background-color: #20c4f4 !important;
    cursor: initial !important;
}

.card-create {
    padding: 10px;
    border: 2px dashed #95aac9;
    color: #95aac9;
    font-weight: bolder;
    cursor: pointer;
    border-radius: 15px;
    min-height: 126px;
    padding-top: 36px;
}

.card-create:hover {
    border: 2px solid #95aac9 !important;
}

/* .event-progress-bar {
    text-align: center;
    color: #95aac9;
    margin-top: 0.25rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
} */

.card .progress-bar.pago {
    background-color: #19F775 !important;
}

.card .progress-bar.aberto {
    background-color:  #575757 !important;
}

.card .progress-bar.faltando {
    background-color: #A71F1C !important;
}

.card .event-progress-bar .progress-sm {
    height: .5rem;
    margin-top: 0.5rem;
    border-radius: 20px;
}

.tag-sit {
    color: #fff;
    border-radius: 25px;
    font-weight: bolder;
    text-align: center;
    width: 60px;
    font-size: 12px;
    padding: 2px;
}

.tag-sit.pago {
    background-color: #19F775 !important;
}

.tag-sit.aberto {
    background-color: #575757 !important;
}

.tag-sit.faltando {
    background-color: #A71F1C !important;
}

.card-name {
    color: #95aac9;
    font-weight: bold;
}

.value-span {
    color: #20c4f4;
    font-size: 40px;
}

.payments-card {
  background-color:  #506D7C;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
}


.date-event {
  text-align: left;
  font: Regular 14px/42px Roboto;
  letter-spacing: 0;
  color: #95aac9;
  opacity: 1;
  text-align: center;
}

.icon-name {
  color: #3f25a8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.borders-values {
  border-top: 2px solid #95aac9;
  border-bottom: 2px solid #95aac9;
}

.values-span {
  /* font: Regular 22px/27px Rubik; */
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  letter-spacing: 0;
  opacity: 1;
}

.values-subspan {
  /* font: Regular 12px/42px Roboto; */
  font-family: "Roboto", sans-serif;
  text-transform: capitalize;
  font-size: 12px;
  letter-spacing: 0;
  opacity: 1;
  margin-top: -20px;
}

.value-div {
  text-align: center;
}
.value-div p {
  margin: 0;
}

.card-chart {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px #00000042;
  border-radius: 8px;
  opacity: 1;
  padding: 5px;
  min-height: 380px;
  max-height: 630px;
}

@media screen and (max-width: 768px) {
  .card-chart {
    margin-top: 10px;
  }
}

.card-chart-header {
  border-bottom: 1px solid #98afbc6b;
}
.card-chart .chart-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.2rem 0 0.5rem 0;
}
.card-chart .chart-header-content span {
  margin: 0;
}
.card-chart .chart-header-content span svg {
  margin: 0 0.5rem;
}
.card-chart .chart-header-content div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
}
.card-chart-header button {
  border: 2px solid !important;
  border-color: #95aac1;
  font-size: 0.8rem;
}
.card-chart-header button:hover {
  border-color: #183153;
  color: #183153;
}
.card-chart-header button.chartbtn-active {
  color: #0050c3;
  cursor: default !important;
  border-color: #0050c3 !important;
}

.card-chart-body {
  padding: 2px;
  margin-top: 10px;
}

.btn-charts {
  border: 1px solid #95aac9;
  border-radius: 8px;
  opacity: 1;
  color: #95aac9;
}

.btn-charts:hover {
  border: 1px solid #3f25a8;
  border-radius: 8px;
  opacity: 1;
  color: #3f25a8;
}

.btn-charts:focus {
  border: 1px solid #3f25a8;
  border-radius: 8px;
  opacity: 1;
  color: #3f25a8;
}
#products .fdKgMy {
  min-height: auto !important;
}
.img-product-dash {
  border: 2px solid #3f25a8;
  opacity: 1;
  width: 45px;
  height: 45px;
  border-radius: 80px;
}

.menu-item-img {
  padding: 0 !important;
}

#dashboard-chart-billing .chartjs-render-monitor {
  max-width: 1100px !important;
  height: auto !important;
  margin: auto;
}
#dashboard-chart-payment .chartjs-render-monitor {
  max-width: 550px !important;
  height: auto !important;
  margin: auto;
}
.adjustment-grafico {
  max-height: 500px;
}

#event-report .card .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  border-left: none !important;
}
#event-report .subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#event-report td a {
  color: #0050c3;
  text-decoration: underline;
}
.type-nav {
  border-bottom: 3px solid #95aac9;
  text-align: center;
  cursor: pointer;
}
.type-nav:hover,
.type-nav.active {
  border-color: #0050c3;
}
.type-nav button {
  border: 0;
  padding-bottom: 0.5rem;
  font-weight: normal !important;
  background-color: transparent;
  color: #95aac9;
  align-items: center !important;
}
.type-nav:hover button,
.type-nav.active button {
  color: #0050c3;
}

#menus-navigation {
  padding: 0.5rem 0.25rem;
}

#menus-navigation #menus-list span {
  color: #95aac9;
}

#menus-navigation #menus-list div {
  margin-top: 0.5rem;
  margin-left: -0.5rem;
}

#menus-navigation #menus-list .btn {
  border-radius: 20px !important;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  width: auto;
  font-size: 0.8rem;
}

#active-menu {
  background-color: #0050c3;
}

#active-menu div {
  color: white;
  padding: 0.5rem 0;
}

#page-menu-items .card-header {
  color: #0050c3;
  background: white;
  justify-content: space-between;
}

#page-menu-items .card-header h5 {
  font-size: 1.2rem;
}

#page-menu-items .deactive-card .menu-category-expand {
  color: #8fa5b2 !important;
  background-color: #e4e9f3;
}

#page-menu-items .deactive-card .card-header {
  background-color: #e4e9f3;
  border-color: #d0dced;
}

#page-menu-items:hover .deactive-card .menu-category-expand {
  color: #8fa5b2 !important;
}

#page-menu-items #btn-new-cat {
  background-color: #95aac91a;
  text-align: center;
  border-radius: 8px;
}

#page-menu-items #btn-new-cat button:hover {
  border: solid #0050c3;
  background-color: #0050c3;
  color: #fff;
}

#page-menu-items #btn-new-cat button {
  border: 2px dashed #0050c3;
  border-radius: 12px;
  cursor: pointer;
  padding: 15px;
  font-weight: normal;
  color: #0050c3;
}

#page-menu-items #create-new-category input,
#page-menu-items #create-new-category button {
  height: 60px;
  width: 100%;
}

#page-menu-items #create-new-category input {
  padding: 15px;
}

.menu-category-row {
  border-radius: 8px;
  overflow: hidden;
}

.menu-category-row .card-header {
  padding: 1.3rem 1.5rem !important;
}

.card-header {
  z-index: 1;
}

.menu-category-row .card-body {
  max-height: 0;
  margin-top: -45px;
  overflow: hidden;
  opacity: 0.5;
  transition: 0.2s linear;
}

.menu-category-row.category-expanded .card-body {
  max-height: 100%;
  margin-top: 0px;
  opacity: 1;
}

.menu-category-expand {
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  color: #0050c3;
}

.menu-category-expand:hover {
  color: #95aac9;
}

.menu-category-row .category-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: -15px;
}

.menu-category-row .category-btns .btn {
  border-radius: 20px !important;
  border: 2px solid !important;
  white-space: nowrap;
}

@media (max-width: 426px) {
}

.product-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  background: white;
  color: #4f6c7c;
  border: 1px solid #edf2f9;
}

.product-row.product-disabled {
  opacity: 0.75;
}

.product-row.product-disabled input,
.product-row.product-disabled .product-row-img {
  opacity: 0.5;
}

.product-row .product-row-img {
  background-size: cover !important;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background: grey;
}

.product-row .product-row-name {
  flex-grow: 1;
  margin: 0 2rem;
}

.product-row .form-group {
  margin: 0;
}

.product-row .form-group span {
  margin-right: 0.5rem;
  color: #8fa5b2;
}

.product-row input {
  background-color: #f1f3f5;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  color: #4f6c7c;
  font-size: 1rem;
  font-weight: 500;
}

.product-row .product-row-actions,
.product-row .product-row-btns {
  display: flex;
  align-items: center;
}

.product-row .product-row-btns .icon-hidden {
  display: none;
}

.product-row .product-row-actions {
  justify-content: space-between;
  min-width: 3rem;
}

.product-row .product-row-actions svg {
  cursor: grab;
}

.product-row .product-row-actions svg:active {
  cursor: grabbing;
}

.product-row .custom-control-label::before,
.product-row .custom-control-label::after {
  top: 3px;
  left: 0;
}

@media (max-width: 426px) {
  #page-menu-items .card-header {
    display: unset !important;
  }

  .product-category {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 10px;
  }

  .product-row {
    width: 48% !important;
    margin: 1% !important;
    float: left !important;
    justify-content: center;
  }

  .product-row-img,
  .product-row-btns {
    order: 1;
  }

  .product-row input {
    width: 100% !important;
    margin: 0.25rem 0 !important;
    order: 2;
    font-size: 0.75rem;
  }
}

.img-product-menu {
  border: 2px solid;
  width: 32px;
  height: 32px;
  margin: 2px;
  border-radius: 50%;
  background-size: cover;
  flex: none;
}

.selectedProduct {
  background-color: #0050c3;
  padding: 4px;
  color: #fff;
  border-radius: 4px;
}

.selectedProduct button {
  color: #fff;
  border: none;
  background-color: transparent;
  margin: 0 2px 0 6px;
}

.selectedProduct button:hover {
  color: #49d1fa;
}

.css-4ljt47-MenuList {
  overflow-x: hidden;
}

.card-category-border-left {
  border-radius: 0.5rem !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  border-left: 4px solid #95aac9 !important;
}

.card-category-border-left:hover {
  border-radius: 0.5rem !important;
  margin-bottom: 1rem !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  border-left: 4px solid #0050c3 !important;
}

.category-grip-icon {
  color: #95aac9;
}

.card-category-border-left:hover .category-grip-icon {
  color: #0050c3;
}

#sectors-setup .custom-control-label::before, #sectors-setup .custom-control-label::after{
    left: 0 !important;
}
.details-user-1 h6{
    font-size: 13px;
}
.details-user-2 {
    margin: 0;
    padding: 0;
}
.details-user-2 h6{
    font-size: 13px;
}
.details-user-info{
    border-bottom: 1px solid #edf2f9;
    padding-bottom: 13px;
}
.details-user-table h6{
    font-size: 13px;
}
.link-whatts{
    color:#128c7e;
    text-decoration: underline;
}
.link-whatts:hover{
    color:#094740;
}
.dropdown-events.event-drop {
  margin: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 12%);
  border-radius: 0 0 7px 7px;
  transition: all 0.2s;
}
.dropdown-events-items {
  padding-top: 2px;
}
.dropdown-events-items:hover {
  cursor: pointer;
  background-color: #600ea3;
  color: #fff;
}

.dropdown-events-items:last-child:hover {
  border-radius: 0 0px 7px 7px;
}
.card-userFraud {
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
}

.table-label {
  background-color: #95aac9;
  color: #fff;
  padding: 16px;
  border-radius: 12px;
}

.button-expand {
  background-color: transparent;
  border: none;
  color: #600ea3;
}

.td-button {
  text-align: end;
}

.subRow {
    border: 2px solid #3f25a8;
  }
  .margin0 {
    margin: 0 !important;
  }
  .qCZjB:hover:not(:disabled){
    background: none;
  }
  .iuCFTz {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  }
  .card .gAuPhq{
    border-radius: 0px !important;
  }
  @media screen and (min-width: 924px) {
    .gAuPhq{
      overflow-x: hidden !important;
    }
  }
  .comprador-detail p{
    color: #4F6C7C;
    font-size: 1em;
  }
  .participantes-header {
    background-color: #f9fbfd !important;
    padding: 10px 0;
    color: #95aac9!important;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.8rem;
  }
  .participante-detail{
    margin-top: 5px;
  }
  .payment-icon{
    width: 25px !important;
    margin-right: 0.5rem;
  }
  button.btn-red-border{
    background-color: transparent !important;
    border: 2px solid #e63757 !important;
    color: #183153;
  }
  .btn-red-border svg{
    color: #e63757;
  }
  button.btn-red-border:hover{
    color: #e63757;
  }
  .sale-btn{
    display: flex;
    justify-content: space-around;
  }
  #table-auditmails thead th{
    cursor: pointer;
  }
  #table-auditmails thead th{
    opacity: 0.5;
  }
  #table-auditmails thead th svg{
    margin-left: 0.25rem;
  }
  #table-auditmails thead th.rotated svg{
    transform: rotate(180deg); 
  }
  #table-auditmails thead th.arrow-active{
    opacity: 1;
  }
  .btn-sendmail{
    width: 150px !important;
    font-size: 0.85rem;
  }

  @media (max-width: 768px) {
    .sale-btn {
      margin-left: 15px;
      width: 30%;
    }
  
    ReactModal__Content ReactModal__Content--after-open{
      transform: translate(-50%, -60%) !important;
    }
  }
.page-header-container {
  font-family: "Lato", serif;
  margin: -40px 0 0 0;
  padding: 1rem 1.7rem 1rem 1.7rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page-header-titles-container {
  display: flex;
  flex-direction: column;
}

.page-header-title {
  font-weight: 700;
  line-height: 36px;
  font-size: 24px;
  font-family: "Lato";
  color: #171719
}

.page-header-subtitle {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #525059
}

@media (max-width: 768px) {
  .page-header-container {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  .page-header-titles-container {
    width: 100%;
    text-align: start;
  }
  .page-header-subtitle {
    text-align: start;
  }
}

#headerGrey {
  background-color: rgb(227 231 240);
  color: #2c607c;
  font-size: 16px;
  padding: 7px 0px 7px 0px;
}
#colorLabel {
  color: #4f6c7c;
}
.statusPay {
  color: #38d85d;
  border: 2px solid #38d85d;
  padding: 2px;
  border-radius: 11px;
}
#btnTable {
  width: 100px;
}
#buttonFooter {
  max-width: 250px;
}
.labelCard {
  font-size: 15px;
}
.tableCard {
  margin: 0px 30px 0px 0px;
}
.table-responsive::-webkit-scrollbar {
  display: none;
}
table.tableCard td {
  padding: 15px;
  color: #4f6c7c;
}
table.tableCard th {
  padding: 15px 15px 0px 15px;
}
.spanPointer {
  cursor: pointer;
  text-decoration: underline;
}
.spanPointer:hover {
  color: #8f44dc;
}
.dropdown-body {
  background-color: #fff;
  color: black;
}

.dropdown-content-handle {
  z-index: 10000;
  position: absolute;
  padding-bottom: 20px;
  padding-left: 5px;
  /* padding: 10px; */
  padding-right: 5px;
  min-height: 200px;
  top: -236px;
  min-width: 90%;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

#arrowDown {
  margin-bottom: 9px;
  cursor: pointer;
  width: 14px;
}

.left-audit-modal {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 50000;
  background-color: white;
  transition: 0.5s;
}

.left-audit-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-audit-modal .card-header h4 {
  font-weight: bold;
}

.audit-modal-visible {
  display: initial;
}

.audit-modal.audit-modal-hidden,
.audit-modal.hide {
  right: -50%;
}

.audit-modal .card-body {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.audit-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.audit-modal .card-header h4 {
  font-weight: bold;
}

@media (max-width: 600px) {
  .table-block {
    width: 100vw;
    display: block;
    overflow-x: auto;
  }
}

#transfermodal-bg{
    opacity: 1;
    background-color: #122740bf;
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap-reverse;
    transition: 0.5s;
}
#transfer-modal .select-search{
    margin-bottom: 0;
}
#transfer-modal .select-search__input .is-invalid{
    border:1px solid red !important;
}
#full-modal.hide #transfermodal-bg{
    opacity: 0;
    z-index: 0;
}
#full-modal.hide .side-modal{
    right: -50%;
}
@media screen and (max-width: 768px) {
#full-modal.hide .side-modal{
    right: -100%;
}
}
/* select.form-control{
    color: #12263f;
} */
#full-modal input[type=number]::-webkit-inner-spin-button, 
#full-modal input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    appearance: none;
    margin: 0; 
}
#modal-order .table td {
  font-weight: normal;
  color: #6e84a3 !important;
}
#modal-order .card-header-title {
  color: #6e84a3;
  font-size: 0.825rem;
  font-weight: bold;
}
.subRow {
  border: 2px solid #3f25a8;
}
.margin0 {
  margin: 0 !important;
}
.qCZjB:hover:not(:disabled) {
  background: none;
}
.iuCFTz {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.card .gAuPhq {
  border-radius: 0px !important;
}

.modal-participantes {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  /* z-index: 10000; */
  background-color: white !important;
  transition: 0.5s;
}

.modal-participantes::-webkit-scrollbar {
  width: 10px;
}

.modal-participantes::-webkit-scrollbar-track {
  background: #fff;
}

.modal-participantes::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  border: 3px solid #600ea3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.left-modal-participantes {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 50000;
  background-color: white;
  transition: 0.5s;
}

.left-modal-participantes .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-modal-participantes .card-header h4 {
  font-weight: bold;
}

.modal-participantes-visible {
  display: initial;
}
.modal-participantes .card-body {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.modal-participantes .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-participantes .card-header h4 {
  font-weight: bold;
}

.editaParticipante {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255) !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-40%, -60%);
}

@media screen and (max-width: 992px) {
  .modal-participantes {
    width: 100%;
    height: 100%;
    right: 0;
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .modal-participantes.modal-participantes-hidden {
    right: -100% !important;
  }

  .editaParticipante {
    transform: translate(-50%, -50%) !important;
  }
}

@media screen and (min-width: 924px) {
  .gAuPhq {
    overflow-x: hidden !important;
  }
}
.comprador-detail p {
  color: #4f6c7c;
  font-size: 1em;
}
.participantes-header {
  background-color: #f9fbfd !important;
  padding: 10px 0;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.8rem;
}
.participante-detail {
  margin-top: 5px;
}
.payment-icon {
  width: 25px !important;
  margin-right: 0.5rem;
}
button.btn-red-border {
  background-color: transparent !important;
  border: 2px solid #e63757 !important;
  color: #183153;
}
.btn-red-border svg {
  color: #e63757;
}
button.btn-red-border:hover {
  color: #e63757;
}
.sale-btn {
  display: flex;
  justify-content: space-around;
}

.sales-details {
  border-bottom: 1px solid #edf2f9;
  padding-bottom: 13px;
}
.sales-details p {
  font-size: 0.8rem;
  margin: 0;
}
.sales-details p b {
  color: #6e84a3;
}
.link-whatts {
  color: #128c7e;
  text-decoration: underline;
}
.link-whatts:hover {
  color: #094740;
}

@media (max-width: 768px) {
  .sale-btn {
    margin-left: 15px;
    width: 30%;
  }
}

.expenses-button {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .expenses-button {
    width: 100%;
  }
}

#export-button {
  min-height: 2.5rem;
}

.tips:hover {
  position: absolute;
}
.card-body{
    padding: 16px 24px;
}

.modal-header-text{
    padding: 0px 5px;
}

.modal-buttons {
    display: flex;
    align-items: center;
}

.input {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 8px !important;
}
  
.input::placeholder{
    color: #767380 !important
}

.card-header-title {
    color: #525059;
}

.modal-header-text {
    color: #2f2e33;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
  
.label-input {
    font-family: Lato !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 21px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: #171719 !important;
}
.control-painel {
    background-color: #4F6C7C;
    width: 100%;
    height: auto;
    /* min-height: 100px; */
    color: #fff !important
}

.control-painel label {
    color: #fff !important;
    display: flex;
}

.form-check {
    display: flex;
    justify-content: space-between;
}

.selector {
    text-align: center;
    align-content: center;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
}

.selector label {
    color: #fff !important;
    margin-bottom: 0 !important;
}

.filters {
    background-color: #4F6C7C;
    width: 100%;
    height: auto;
    color: #fff
}

.line-top{
    border-top: 1px solid #fff;
    align-content: center;
}
.equipment-general {
  margin: 0;
}
.equipment-general .card-title-event {
  border-radius: 8px;
}
.equipment-general h1 {
  font-size: 30px;
  color: #600ea3;
  font-weight: 100;
}
.equipment-general h2 {
  font-size: 17px;
  color: #95aac9;
  font-weight: 100;
  font-style: italic;
}
.licenses-infos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.card-item span {
  font-size: 0.8rem;
}
.card-item .card-item-icon {
  color: #95aac9;
  font-size: 21px;
  margin-top: -5px;
}
.CircularProgressbar {
  height: 6rem;
}
.container-fluid .content-title span {
  font-weight: 100;
  font-size: 1.5rem;
  color: #0050c3;
}
.modalCircularProg {
  top: 50px;
  left: 25px;
}

/* #list-establishments .card-row{
    display: flex;
    justify-content: flex-start;
    height: 80px;
} */

#list-establishments .card-establishments{
    justify-content: space-between;
    padding: 0.75rem 0;
    width: 100%;
}
#list-establishments.card-list .card-grid {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100%;
}
#list-establishments.card-box .card-establishments .card-establishments-main {
    flex-wrap: wrap;
}
#list-establishments .card-establishments .card-establishments-name {
    width: calc(100% - 115px);
    margin-left: 1rem;
}
#list-establishments .card-establishments .establishments-name{
    max-width: 100%;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* CARDS IN GRID */
#list-establishments.card-box .card-establishments {
    height: 280px;
    float: left;
}
#list-establishments.card-box .card-establishments-infos, #list-establishmentss.card-box .establishments-progress-bar, #list-establishments.card-box .card-establishments .card-establishments-name {
    width: 100%;
    min-width: 100%;
}
#list-establishments.card-box .card-establishments-infos{
    display: block;
    position: absolute;
    top: 0.75rem;
}
#list-.card-box .card-establishments .card-date{
    position: absolute;
    top: 0.5rem;
    left: 1.5rem;
}
#list-establishmentss.card-box .card-establishments .establishments-img{
    width: 100%;
    height: 150px;
    border-radius: 0;
    margin-left: 0;
}
#list-establishments.card-box .card-establishments .card-establishments-name{
    margin-left: 0;
    margin-top: 0.5rem;
}
#list-establishments.card-box .establishments-progress-bar {
    margin-top: 200px;
    text-align: left;
}
#list-establishments.card-box .dotsMobile {
    position: absolute;
    top: 200px;
}
/* CORREÇÃO BOOTSTRAP */
@media (min-width: 1400px){
.col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
}
}

@media (max-width: 768px) {
    #list-establishments .card-establishments-infos, #list-establishments .establishments-progress-bar {
        width: 100%;
        max-width: 100%;
        flex-wrap: initial;
    }
    #list-establishments .card-row-name{
        max-width: calc(100% - 50px);
        text-overflow: ellipsis;
        overflow: hidden;
        position: absolute;
        left: 50px;
    }
    #list-establishments.card-box .establishments-progress-bar {
        text-align: left;
    }

    /* AJUSTES RESPONSIVOS PARA LAYOUT EM LISTA */
    #list-establishments.card-list .establishments-progress-bar .row {
        flex-flow: column;
        align-items: start;
        flex-wrap: wrap;
    }
    #list-establishments.card-list .card-establishments .card-date {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        padding: 0 0.3rem;
        background-color: #183153;
    }
    #list-establishments.card-list .card-establishments .card-date .date-day {
        font-size: 0.7rem;
    }
    #list-establishments.card-list .card-establishments .card-date .date-month {
        font-size: 0.7rem;
        margin: 0;
        font-weight: bold;
        margin-left: 0.3rem;
    }
    #list-establishments.card-list .card-establishments .establishments-location, #list-establishments.card-list .card-establishments .establishments-img{
        width: 40px;
        height: 40px;
    }

}

.card-establishments {
    position: relative;
    margin-top: 15px;
    background: #ffffff;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* transition: 0.3s; */
}

.card-establishments:hover {
    background: #edf2f9;
}

.card-establishments-main {
    margin-top: 0.25rem;
}

.card-establishments-main,
.card-establishments .card-establishments-infos {
    display: flex;
    align-items: center;
}

.card-establishments .card-date {
    width: 50px;
    min-width: 50px;
    height: 55px;
    background: #95aac9;
    border-radius: 8px;
    text-align: center;
}

.card-establishments-name {
    margin-left: 1rem;
}

#establishments-date-filter,
#establishments-total-value {
    display: none;
}

.establishments-progress-bar {
    text-align: center;
    color: #95aac9;
    margin-top: 0.25rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}
.card-establishments .progress-bar {
    background-color: #00d97e;
}
.card-establishments .establishments-progress-bar .progress-sm {
    height: .5rem;
    margin-top: 0.5rem;
    border-radius: 20px;
}

.card-recipes {
    width: 100%;
}

.date-day {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 25px;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
}

.date-month {
    text-align: left;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
    text-align: center;
    padding-top: -10px;
    margin-top: -10px;
}

.establishments-img {
    width: 55px;
    height: 55px;
    background-color: #e9ecef;
    border-radius: 80px;
    background-size: cover;
    flex: none;
}

.establishments-name {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    color: #183153;
    opacity: 1;
    font-weight: bold;
    margin: 0;
}

.toogle-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.toogle-button svg {
    margin-right: 0.5rem;
}

.event-online,
.establishments-offline {
    font-size: 0.8rem;
    color: #12263f;
    display: flex;
    align-items: center;
}
.show-dropdown {
    display: block !important;
}
#list-establishments .dotsMobile {
    /* position: absolute; */
    padding: 10px;
    top: 0;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    right: 1rem;
    z-index: 9000;
    color: #95aac9;
    background: transparent;
    border: none;
    cursor: pointer;
}

#list-establishments .dotsMobile svg{
    position: absolute;
    top: 1px;
}
#list-establishments .card-establishments .dropdown-buttons {
    background-color: #fff;
    border: 1px solid rgba(18,38,63,.1);
    border-radius: .375rem;
    display: none;
    list-style: none;
    padding: .5rem 0;
    position: absolute;
    top: 20px;
    right: 30px;
    z-index: 10000;
    padding: 0.5rem 0;
    margin-right: 10px;
}

#list-establishments .event-status{
    color: #6e84a3;
    font-size: 1rem;
    margin-right: 0.5rem;
}
#list-establishments .event-online::after {
    content: "online";
}
#list-establishments .event-offline::after {
    content: "offline";
}

@media (max-width: 768px) {
    .dotsMobile {
        top: 0.5rem;
        right: 0.75rem;
    }
    .card-establishments-main {
        display: block;
    }
    .card-establishments-main,
    .card-establishments .card-establishments-infos {
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
    .card-establishments .establishments-name {
        font-size: 0.8rem !important;
    }
    .custom-color {
        visibility: hidden !important;
    }
    .card-establishments .establishments-progress-bar .progress {
        min-width: 0px;
    }

    #list-establishments.card-box .card-establishments-name {
        width: 120px;
        margin-left: 0px !important;
        height: 60px;
        overflow: hidden;
    }

    #list-establishments.card-box .card-establishments-infos  {
        height: 100px;
    }

    .dropdown-menu-show {
        width: 300px;
    }
}

.dropdown-menu {
    z-index: 1;
}

.dropdown-menu-show {
    margin-right: 25px !important;
    margin-top: -10px !important;
}

.dropdown-item {
    background-color: initial;
    border: 0;
    clear: both;
    color: #6e84a3;
    display: block;
    font-weight: 400;
    padding: 0.375rem 1.2rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;
}

.dropdown-item svg {
    margin-right: 1rem;
    width: 1rem;
}

.dropdown-toggle::after {
    display: none !important; 
  }
#register-establishment .btn-secondary {
  width: 100%;
}
#register-establishment .input-group-text {
  font-size: 0.75rem;
}
.type-cards button {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
  color: #495057;
  background-color: #dae0e5;
  margin-bottom: 0.5rem;
}
.type-cards button img {
  max-width: 1.8rem;
  max-height: 1rem;
  margin-right: 0.5rem;
}

.btn-type {
  width: 100%;
  background: #f1f9f9;
  border: 2px solid #f1f9f9;
  border-radius: 8px;
  font-size: 0.85rem;
  background: #f1f9f9;
  color: #8fa5b2;
  padding: 0 0.35rem !important;
  height: 35px;
}
.btn-type:hover {
  background-color: #8fa5b2;
  color: #fff;
}
.btnGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btnClass {
  width: 100%;
}
.btnClass img {
  width: 25px;
  filter: invert(60%) sepia(26%) saturate(208%) hue-rotate(158deg)
    brightness(101%) contrast(94%);
}
.btnClass.active .btn-type {
  background-color: #22ee98;
  border-color: #00d97e;
  color: white;
}
.btnClass.active .btn-type:hover {
  background-color: #00d97e;
  border-color: #05a261;
  color: white;
}
.btnClass.active img {
  filter: brightness(1000%) !important;
}
.btnGroup .payment-delete {
  position: absolute;
  color: #6e84a3;
  right: 1.5rem;
  cursor: pointer;
}
.btnClass.active .payment-delete {
  color: white !important;
}
.btnGroup .payment-delete:hover {
  color: #e63757;
}

#subheader {
  background-color: white;
}
#subheader .nav-tabs {
    border-bottom-color: #e3ebf6;
  }
  #subheader .nav-link{
    background-color: transparent;
    border: 0;
    border-bottom: 3px solid transparent;
    margin-right:0.5rem;
    color: #95aac9;
    font-size: 0.8rem;
    cursor: pointer;
  }
  #subheader .nav-item:last-child .nav-link{
    margin-right:0;
  }
  #subheader .nav-link:hover{
    color: #6e84a3;
  }
  #subheader .nav-link.active{
    border-bottom-color: #2c7be5;
    color: #12263f;
  }
#list-events .card-event {
  justify-content: space-between;
  padding: 0.75rem 0;
  width: 100%;
}

#list-events.card-list .card-grid {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100%;
}

#list-events.card-box .card-event .card-event-main {
  flex-wrap: wrap;
}

#list-events .card-event .card-event-name {
  width: calc(100% - 115px);
  margin-left: 1rem;
}

#list-events .card-event .event-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* CARDS IN GRID */
#list-events.card-box .card-event {
  height: 290px;
  float: left;
}

#list-events.card-box .card-event-infos,
#list-events.card-box .event-progress-bar,
#list-events.card-box .card-event .card-event-name {
  width: 100%;
  min-width: 100%;
}

#list-events.card-box .card-event-infos {
  display: block;
  position: absolute;
  top: 0.75rem;
}

#list-events.card-box .card-event .card-date {
  position: absolute;
  top: 0.5rem;
  left: 1.5rem;
}

#list-events.card-box .card-event .event-img {
  width: 100%;
  height: 150px;
  border-radius: 0;
  margin-left: 0;
}

#list-events.card-box .card-event .card-event-name {
  margin-left: 0;
  margin-top: 0.5rem;
}

#list-events.card-box .event-progress-bar {
  margin-top: 200px;
  text-align: left;
}

#list-events.card-box .dotsMobile {
  position: absolute;
  top: 200px;
}

.amount-menu {
  background-color: #d8e2e7;
  color: #183153;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 15px;
  margin-left: 10px;
}

.filter-amount {
  background-color: #2075f4;
  color: #fff;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 15px;
  margin-left: 10px;
}

.icon-menu {
  transform: rotate(-40deg);
}

.btn-cards-event {
  padding: 12px;
}

#btntableview-1 svg {
  margin-left: 10px;
  box-sizing: content-box;
}

#btntableview-2 svg {
  margin-left: 10px;
  box-sizing: content-box;
}

.btn-outline-primary-export {
  border: 2px solid #8fa5b2;
  color: #8fa5b2;
}

.btn-outline-primary-export:hover {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3;
}

.btn-outline-primary-events {
  border: 2px solid #2075f4;
  color: #2075f4;
}

.btn-outline-primary-events:hover {
  background-color: #6495ed;
  color: #fff;
  border-color: #6495ed;
}

/* CORREÇÃO BOOTSTRAP */
@media (min-width: 1400px) {
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 768px) {
  #list-events .card-event {
    flex-direction: column;
    display: inline-table;
  }

  #list-events .card-event-infos,
  #list-events .event-progress-bar {
    width: 100%;
    max-width: 100%;
  }

  #list-events.card-box .event-progress-bar {
    text-align: left;
  }

  /* AJUSTES RESPONSIVOS PARA LAYOUT EM LISTA */
  #list-events.card-list .event-progress-bar .row {
    flex-flow: column;
    align-items: start;
    flex-wrap: wrap;
  }

  #list-events.card-list .card-event .card-date {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding: 0 0.3rem;
    background-color: #183153;
  }

  #list-events.card-list .card-event .card-date .date-day {
    font-size: 0.7rem;
  }

  #list-events.card-list .card-event .card-date .date-month {
    font-size: 0.7rem;
    margin: 0;
    font-weight: bold;
    margin-left: 0.3rem;
  }

  #list-events.card-list .card-event .event-location,
  #list-events.card-list .card-event .event-img {
    display: none;
  }

  #list-events.card-list .dotsMobile {
    position: absolute !important;
    top: 30px !important;
  }
}

.card-event {
  position: relative;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* transition: 0.3s; */
}

.card-event:hover {
  background: #edf2f9;
}

.card-event-main {
  margin-top: 0.25rem;
}

.card-event-main,
.card-event .card-event-infos {
  display: flex;
  align-items: center;
}

.card-event .card-date {
  width: 50px;
  min-width: 50px;
  height: 55px;
  background: #95aac9;
  border-radius: 8px;
  text-align: center;
}

.card-event-name {
  margin-left: 1rem;
}

#events-date-filter,
#events-total-value {
  display: none;
}

.event-progress-bar {
  text-align: center;
  color: #95aac9;
  margin-top: 0.25rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.card-event .progress-bar {
  background-color: #00d97e;
}

.card-event .event-progress-bar .progress-sm {
  height: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 20px;
}

.card-recipes {
  width: 100%;
}

.date-day {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.date-month {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  padding-top: -10px;
  margin-top: -10px;
}

.event-img {
  width: 55px;
  height: 55px;
  background-color: #e9ecef;
  border-radius: 80px;
  background-size: cover;
  flex: none;
  margin-left: 10px;
}

.event-name {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  color: #183153;
  opacity: 1;
  font-weight: bold;
  margin: 0;
}

.toogle-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toogle-button svg {
  margin-right: 0.5rem;
}

.show-dropdown {
  display: block !important;
}

.type-event-text{
  color: #2C7BE5;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

#list-events .dotsMobile {
  /* position: absolute; */
  padding: 10px;
  top: 0;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  right: 1rem;

  /* z-index: 9000; */

  color: #95aac9;
  background: transparent;
  border: none;
  cursor: pointer;
}

#list-events .dotsMobile svg {
  position: absolute;
  top: 1px;
}

#list-events .card-event .dropdown-buttons {
  background-color: #fff;
  border: 1px solid rgba(18, 38, 63, 0.1);
  border-radius: 0.375rem;
  display: none;
  list-style: none;
  padding: 0.5rem 0;
  position: absolute;
  top: 25px;
  right: 35px;
  z-index: 10000;
  padding: 0.5rem 0;
  margin-right: 10px;
}

#list-events .event-status {
  color: #6e84a3;
  font-size: 1rem;
  margin-right: 0.5rem;
}

#list-events .event-online::after {
  content: "vendas ativas";
}

#list-events .event-offline::after {
  content: "evento offline";
}

#list-events .event-encerrado::after {
  content: "evento encerrado";
}

#list-events .event-cancelado::after {
  content: "evento cancelado";
}

#list-events .event-adiado::after {
  content: "evento adiado";
}

#change-status-modal {
  width: 350px;
  max-width: 95%;
}

#change-status-modal .close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.change-status-modal-container {
  font-size: 0.75rem;
  padding: 1rem;
}

.change-status-modal-container h4 {
  font-size: 1rem;
  font-weight: bold;
}

.event-organization {
  color: #183153;
}
.event-organization:hover {
  color: #0d67e2;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .dotsMobile {
    top: 0.5rem;
    right: 0.75rem;
  }

  .card-event-main {
    display: block;
  }

  .card-event-main,
  .card-event .card-event-infos {
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    top: 4px;
  }

  .card-event .event-name {
    font-size: 0.8rem !important;
  }

  .custom-color {
    visibility: hidden !important;
  }

  .card-event .event-progress-bar .progress {
    min-width: 0px;
  }

  #list-events.card-box .card-event-name {
    width: 120px;
    margin-left: 0px !important;
    height: 60px;
    overflow: hidden;
  }

  #list-events.card-box .card-event-infos {
    height: 100px;
  }

  .btn-cards-event {
    margin-left: 15px;
    margin-right: 15px;
  }

  #btntableview-1::after {
    content: "Visualizar em cards";
  }

  #btntableview-2::after {
    content: "Visualizar em lista";
  }
}

@media (max-width: 600px) {
  table {
    width: 100vw;
    display: inline-table;
    overflow-x: auto;
  }
}

#producer-infos p{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #edf2f9;
}
#producer-infos p span:first-child{
    font-weight: bold;
    color: #283e59;
}
#producer-infos a{
    font-weight: normal;
    text-decoration: underline;
    color: #2c7be5;
}
.icom-bar {
  font-size: 25px;
  transform: rotate(-45deg);
  margin-left: -100px;
  margin-right: 80px;
}

.icons-ticket {
  font-size: 25px;
  transform: rotate(-40deg);
  margin-left: -20px;
  margin-right: 25px;
}

.styleModal {
  position: absolute;
  inset: 50% 40% auto 60%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 0px;
  width: 520px;
  height: 470px;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .styleModal {
    width: 100%;
    max-width: 356px;
    height: 100%;
    max-height: 500px;
    position: absolute;
    inset: 50% 40% 50% 50%;
    transform: translate(-50%, -50%);
  }

  .icons-bar {
    width: 20px;
  }

  .icons-ticket {
    width: 20px;
  }

  .button-event-aeb {
    text-align: center;
  }
}

.button-dashed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #2075f4;
  line-height: 1.40625rem;
  font-size: 0.9375rem;
  font-weight: 400;
  border: 2px dashed #2075f4;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.button-dashed:hover {
  background-color: #6495ed;
  border-color: #6495ed;
  color: #fff;
}
.button-dashed:hover img {
  filter: brightness(10);
}

:root {
    --t-bold: #12263F;
    --t-normal: #4F6C7C;
    --d-color: #95AAC9;
}

.t {
    font-family: 'Roboto';
    font-style: normal;
}

.t-img {
    color: var(--t-bold);
    font-weight: 400;
    line-height: 1.2rem;
    font-size: 1.375rem;
}

.t-sm {
    color: var(--t-normal);
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 700;
}

.t-mid {
    color: var(--t-normal);
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 700;
}

.t-bold {
    color: var(--t-bold);
    font-size: 1.25rem;
    line-height: normal;
    font-weight: 700;
}

.d {
    color: var(--d-color);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}

.d-txt {
    font-size: 0.875rem;
    line-height: 1.40625rem;
}

.d-img {
    font-size: 1rem;
    line-height: 1.5rem;
}

.clickable-text {
    color: #2c7be5;
    font-weight: bold;
    cursor: pointer;
}

hr {
    margin-top: 0;
}
* {
    font-family: 'Lato';
}

.modal-dialog {
    max-width: 512px;
    max-height: 426px;
}

.modal-content {
    border-radius: 8px;
    padding: 32px;
}

.modal-header {
    border-bottom: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
}

.modal-title {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    text-align: center;
}

.modal-body {
    font-size: 14px;
    color: #555555;
    line-height: 1.6;
    padding: 0;
    text-align: left;
}

.modal-body ul {
    padding-left: 1.2rem;
    list-style-type: disc;
    margin-top: 24px;
    margin-bottom: 1rem;
}

.modal-body li {
    margin-bottom: 0.5rem;
}

.modal-body strong {
    color: #222222;
}

.modal-body p {
    font-size: 0.95rem;
    color: #777777;
}

.modal-body a {
    color: #007bff;
    text-decoration: underline;
}

.modal-body a:hover {
    color: #0056b3;
}

.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 24px;
}

.modal-footer .btn-primary {
    background-color: transparent;
    border: 1px solid #0050C3;
    color: #0050C3;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 700;
}

.modal-footer .btn-primary:hover {
    background-color: transparent;
}

.modal-body p.modal-footer-text {
    font-size: 14px;
    font-weight: 400;
    color: #525059;
    line-height: 1.6;
    margin-top: 1rem;
}

.modal-footer-text a {
    text-decoration: none;
    color: #1C70E9;
    font-weight: 700;
}

.modal-header .close {
    font-size: 1.25rem;
    color: #999999;
}

.modal-header .close:hover {
    color: #666666;
}

@media (max-width: 768px) {
    .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }

    .modal-content {
        width: inherit;
        height: inherit;
        padding: 24px;
    }

    .modal-header {
        height: 56px;
        margin-bottom: 24px;
        margin-right: 10px;
    }

    .modal-footer {
        margin: 0;
    }

    .modal-title {
        text-align: left;
    }
}
.img-group-wl {
    border-radius: 0.25rem;
    border: 1px dashed #5B4299;
}

.img-group {
    border-radius: 0.50rem;
    border: 1px dashed #2075F4;
    padding: 1.25rem;
}

.dropzone {
    position: relative;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
}

.dropzone-banner-wl {
    display: flex;
    width: 100%;
    height: 21.875rem;
    padding: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropzone-banner {
    display: flex;
    width: 321px;
    height: 400px;
    padding: 0.25rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropzone-banner:hover {
    cursor: pointer;
    background-color: #C8E6FA33;
}

.dropzone-thumb {
    display: flex;
    width: 100%;
    height: 16rem;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    align-self: stretch;
}

.thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 0.625rem;
    gap: 0.625rem;
    align-self: stretch;
}

.w-preview-group:hover .w-preview {
    visibility: visible;
}

.w-preview:hover .img-preview {
    opacity: 0.2;
}

.img-preview:hover {
    opacity: 0.2;
}

.img-preview {
    transition: 200ms;
    border-radius: 0.25rem;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.w-preview {
    visibility: hidden;
}

.title-banner {
    font-weight: 400;
    color: #4F6C7C;
    font-size: 18px;
    line-height: 28px;
    margin-top: 10px;
}

.trash {
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 0;
    z-index: 10;
    width: -moz-fit-content;
    width: fit-content;
}

.file-info {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #4F6C7C;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
}

.separator {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.accepted-formats {
    padding-left: 0.3rem;
}

@media (max-width: 768px) {
    .dropzone-banner {
        height: 400px !important;
    }

    .dropzone-banner-wl {
        height: 10rem;
    }

    .dropzone-thumb {
        height: 0;
        padding-bottom: 80%;
        padding-top: 2rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .dropzone-thumb .img-preview {
        margin-top: 70%;
    }

    .thumb {
        padding-top: 100%;
    }
}
.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.PhoneInput input::placeholder {
  color: #b1cdea !important;
}
.PhoneInput input:focus-visible {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-color: #00a5e3 !important;
  box-shadow: inset 0 1px 1px rgba(241, 38, 38, 0.075),
    0 0 8px rgba(0, 162, 255, 0.473) !important;
}

.subtitle-contact {
  color: #95aac9;
  font-size: 12px;
}
.PhoneInputCountry {
  border: 1px solid #ced4da;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-right: none;
  margin-right: 0;
  padding: 0.5rem;
  background-color: #f3f6f9;
}
.PhoneInputCountrySelectArrow {
  height: 8px;
  width: 8px;
}

.sponsor-logo {
  width: 3.9375rem;
  height: 3.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background: #ffffff;
  background-size: cover;
  background-position: center;
}

.trash {
  cursor: pointer;
}

.edit-text {
  color: var(--neutral-color-dark-d-5, #767380);
  font-feature-settings: "clig" off, "liga" off;
}

.delete-text {
  color: var(--error-color-error-medium, #f53c47);
  font-feature-settings: "clig" off, "liga" off;
}

.pop {
  padding: 0.5rem;
}

.sponsor-card {
  padding: 1.25rem 1.25rem 1.25rem 0.5rem;
  grid-gap: 2rem;
  gap: 2rem;
  border-radius: 0.5rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  background: var(--neutral-color-light-l-5, #fff);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);

  color: var(
    --painel-zig-tickets-event-create-1920-x-1080-default-abbey,
    #495057
  );

  /* painel_zig_tickets_event_create_1920x1080_default/Roboto/Regular 15 */
  font-family: Roboto;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.40625rem; /* 150% */
}

.sponsor-group {
  grid-gap: 2rem;
  gap: 2rem;
}

.more:hover {
  cursor: pointer;
}

.tooltip-group {
  grid-gap: var(--menu-row-gap, 0.25rem);
  gap: var(--menu-row-gap, 0.25rem);
  padding: 0.5rem 1rem;
  width: 100%s;
  transition: 200ms;
}

.tooltip-group p {
  margin: 0;
}

.tooltip-group:hover {
  cursor: pointer;
  background-color: #eaeaec;
}

.pencil:hover {
  cursor: pointer;
}

.draggable {
  border-left: 4px solid var(--neutral-color-dark-d-5, #767380);
  transition: 200ms;
}

.draggable:hover {
  border-left: 4px solid var(--primary-color-primary-pure, #0050c3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
  .sponsor-text-link {
    text-overflow: ellipsis;
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
  }

  .sponsor-text {
    text-align: left;
    text-overflow: ellipsis;
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
  }

  .sponsor-texts {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    flex: 1 0;

    overflow: hidden;
    color: var(
      --painel-zig-tickets-event-create-1920-x-1080-default-abbey,
      #495057
    );
    text-overflow: ellipsis;

    width: 20%;
    flex-direction: column;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
  }
  .sponsor-card {
    grid-gap: 0.5rem;
  }

  .sponsor-group {
    grid-gap: 0.5rem;
  }
}

.button-outline {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: transparent;
  border-radius: 0.25rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}
.primary {
  border: 1px solid #5b4299;
  color: #5b4299;
}
.primary.button-outline:hover {
  background-color: rgba(96, 14, 163, 0.05);
}
.primary.button-outline:active {
  background-color: rgba(96, 14, 163, 0.3);
}

.sponsors-container {
  padding: 1.25rem;
  grid-gap: 1rem;
  gap: 1rem;
  display: grid;
}
.sponsors-logo-container {
  width: 3.9375rem;
  height: 3.9375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background: #d9d9d9;
  background-size: cover;
}
.sponsors-logo-button-container {
  color: #95aac9;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00938rem;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
}
.sponsors-logo-button-content {
  width: 10.875rem;
}
.sponsor-label-input {
  color: rgba(110, 132, 163, 0.68) !important;
  font-feature-settings: "clig" off, "liga" off !important;
  font-family: Roboto !important;
  font-size: 0.75rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.00938rem !important;
}
.sponsor-input-container {
  grid-gap: 0.25rem;
  gap: 0.25rem;
}
.add-sponsor-button-container {
  padding: 1rem 0 0 0;
}

.add-sponsor-button-content {
  width: 6.5625rem;
}

.sponsor-list-container {
  max-height: 300px;
  overflow-y: scroll;
}

.custom-confirm-button-outline {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border-radius: 0.25rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.custom-confirm-button-outline {
  border: 1px solid #5b4299 !important;
  color: #5b4299 !important;
}

.custom-confirm-button-outline:hover {
  background-color: rgba(96, 14, 163, 0.05) !important;
  background-image: none !important;
}

.custom-confirm-button-outline:active {
  background-color: rgba(96, 14, 163, 0.3) !important;
}

.custom-confirm-button-outline:focus {
  box-shadow: none !important;
}

.dropzone {
  display: flex;
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  align-self: stretch;
}

.char-count {
  background-color: #F9F9F9;
  border-bottom: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
  color: #4C4C4C;
  padding: 0.25rem 1rem;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: end;
}

.postal-code {
  font-size: 10px;
  margin-top: 2px;
  font-weight: 700;
}
.cep-info {
    color: #2C7BE5;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
}

@media (max-width: 767px) {
    .cep-info {
        margin-top: 0;
    }
}
@media screen and (max-width: 768px) {
  .table-create-events {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .event-sector {
    width: 540px;
  }

  .qtd-create-events {
    width: 100px !important;
  }

  .value-create-events {
    width: 100px !important;
  }

  .date-create-events {
    width: 100px !important;
  }

  
}

.title-published{
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    text-align: center;
    color: #2C7BE5;
    max-width: 40rem;
}

.information-published {
    font-weight: 300;
    font-size: 16px;
    line-height: 23.8px;
    text-align: center;
    color: #495057;
}

.logo-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
  }
  

@media (max-width: 768px) {
    .buttons-container-published {
        flex-direction: column-reverse;
        grid-gap: 10px;
        gap: 10px;
    }
    .mobile {
        width: 100%;
    }
    .title-published{
        font-size: 28px;
        line-height: 36.4px;
    }
}
.producer-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}

.producer-row:hover {
  background-color: #f9fbfd;
}

.producer-row .producer-info {
  display: flex;
  align-items: center;
  flex-wrap: inherit;
}

.producer-row .img-user {
  width: 45px;
  height: 45px;
  margin-right: 1rem;
}

.label-switch::before,
.label-switch::after {
  width: 40px !important;
}

.custom-switch .custom-control-input:checked~.label-switch::after {
  transform: translateX(1.5rem) !important;
}

.custom-switch .label-switch::after {
  width: calc(1rem - 4px) !important;
}

.form-control.error,
.form-control.error:focus,
.form-control.error:hover {
  border: 1px solid red !important;
  outline: 0;
}

.form-control.error:focus {
  box-shadow: inset 0 1px 1px rgba(241, 38, 38, 0.075),
    0 0 8px rgba(255, 0, 0, 0.473) !important;
}

#user-data .row {
  margin-bottom: 10px;
}

.img-box {
  height: 150px;
  text-align: left;
  margin-top: 20px;
}

.img-box img {
  height: 100%;
}

.header-container {
  padding-top: 1rem;
  padding-left: 1rem;
}

p {
  margin-bottom: 0.5rem;
}

.card-header {
  z-index: 0
}
#subheader {
  font-family: "Lato", serif;
}

#subheader .nav-tabs {
  border-bottom-color: #e3ebf6;
}
#subheader .nav-link{
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid transparent;
  margin-right:0.5rem;
  color: #95aac9;
  font-size: 0.8rem;
  cursor: pointer;
}
#subheader .nav-item:last-child .nav-link{
  margin-right:0;
}
#subheader .nav-link:hover{
  color: #6e84a3;
}
#subheader .nav-link.active{
  border-bottom-color: #0050C3;
  color: #525059;
}
.createevent-datepicker .input-group-text {
  width: 60px;
  height: 40px;
  justify-content: center;
}

.createevent-datepicker {
  align-items: center;
}

.sponsor-cards {
  grid-gap: 1rem;
  gap: 1rem;
}



.custom-confirm-button-outline {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border-radius: 0.25rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.custom-confirm-button-outline {
  border: 1px solid #5b4299 !important;
  color: #5b4299 !important;
}

.custom-confirm-button-outline:hover {
  background-color: rgba(96, 14, 163, 0.05) !important;
  background-image: none !important;
}

.custom-confirm-button-outline:active {
  background-color: rgba(96, 14, 163, 0.3) !important;
}

.custom-confirm-button-outline:focus {
  box-shadow: none !important;
}

.sponsor-count {
  color: #95aac9;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.00938rem;
}

.empty-msg h3 {
  color: #12263f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.00938rem;
}

.empty-msg p {
  color: #95aac9;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00938rem;
}

.createevent-type button {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  font-weight: bold;
  color: #495057;
  background-color: #dae0e5;
  margin-bottom: 0.5rem;
}

.border-nav {
  border-bottom: 3px solid #95aac9;
  text-align: center;
  margin: 1rem auto 0 auto;
  cursor: pointer;
}

.border-nav:hover,
.border-nav.active {
  border-color: #0050c3;
}

.border-nav button {
  border: 0;
  padding-bottom: 0.5rem;
  font-weight: normal !important;
  background-color: transparent;
  color: #95aac9;
  align-items: center !important;
}

.border-nav:hover button,
.border-nav.active button {
  color: #0050c3;
}

.event-type {
  margin-top: 28px;
}

.first-sale-info {
  color: #4f6c7c;
  font-size: 12px;
}

.back-currency-class {
  background-color: transparent !important;
  color: black !important;
  border: 1px solid #d3d2d9 !important;
}

.options-container {
  display: flex;
  padding-left: 5px;
  padding-top: 5px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem 0.5rem 0.5rem 1.8rem;
  border: 1px solid #ececec;
  border-radius: 8px;
}
.suggestions {
  border: 1px solid #ececec;
  border-radius: 8px;
  border-top-width: 0;
  list-style: none;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  padding-left: 0rem;
  width: 100%;
}
.suggestions li {
  padding: 0.5rem;
  font-size: 14px;
  color: #6e84a3;
    font-size: 0.75rem;
}
.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #ececec;
}
.suggestion-active, .suggestions li:hover {
  background-color: #3f25a8;
  color: #fff !important;
  cursor: pointer;
  font-weight: 700;
}
.input-custom-input .event-search{
  width: 100%;
}
.event-search-input{
  position: absolute;
  width: 100%;
  top: 60px;
  z-index: 10;
  background: white;
}

#vgprodutor .card-search{
  position: relative;
}
#vgprod #vgprodutor img.imagem-link {
  width: 30px;
  padding-right: 10px;
}
#vgprod #vgprodutor input.pesquisa {
  width: 96%;
  height: 30px;
  border-radius: 5px;
  border-style: none;
  box-shadow: none;
  font-size: 14px;
}
#vgprod #vgprodutor .tituloprod {
  margin: 2rem 0;
  color: #4f6c7c;
}

#vgprod #vgprodutor div p.sinais img {
  width: 30px;
}
#vgprod #vgprodutor #card div p.center {
  text-align: center;
}
#vgprod #vgprodutor #card div p.primary {
  color: #3f25a8;
}

#vgprod #vgprodutor #card .valorconta h5 {
  color: #fff !important;
}

#vgprod #vgprodutor #card .card h5 {
  color: #4f6c7c;
}

#vgprod #vgprodutor .row .valorconta p .imagem-info {
  width: 25px;
  padding-left: 10px;
}

#vgprod #vgprodutor .row div p.igual {
  font-size: 22px;
}
.conteudovgprod {
  background-color: #fff;
  border-radius: 10px;
  /* height: 100vh; */
  padding-bottom: 30px;
}
#vgprod .financeiro-btn button{
  width: 100%;
}

#vgprod #vgprodutor .conteudovgprod .intable {
  display: flex;
  flex-direction: row;
  background-color: #e9f2f2;
  margin-top: 20px;
  justify-content: space-between;
}
#vgprod #vgprodutor .conteudovgprod .intable-values {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  justify-content: space-between;
}

#vgprod #vgprodutor .conteudovgprod .intable-values2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-right: 15px;
}

#vgprod #vgprodutor .conteudovgprod .intable-values p {
  font-size: 12px;
  width: 150px;
  margin: 0;
  white-space: nowrap;
}
#vgprod #vgprodutor .conteudovgprod .intable-values2 p {
  font-size: 12px;
  width: 150px;
  margin: 0;
  white-space: nowrap;
}

#vgprod #vgprodutor .conteudovgprod .intable h5,
#vgprod #vgprodutor .conteudovgprod .intable h6 {
  color: #4f6c7c;
}
#vgprod #vgprodutor .conteudovgprod .intable h6 {
  padding-left: 15px;
  padding-top: 10px;
}
#vgprod #vgprodutor .conteudovgprod .intable h5 {
  padding-top: 10px;
  padding-right: 15px;
}
#vgprod .card{
  margin: 0;
}
.bermelho h5,
.bermelho h6 {
  color: #c01c1c !important;
}

.bermelho {
  color: #c01c1c !important;
  font-weight: bold;
}

.canetazul {
  color: #489cb9;
  font-weight: bold;
}

.azulcaneta {
  color: #4f6c7c;
  font-weight: bold;
}

.coluna11 {
  margin-left: 15px;
}

.coluna2 {
  text-align: center;
}

.coluna3 {
  text-align: right;
}
.new-input {
  display: flex;
  width: 100%;
  padding: 0.5rem 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  border: none;
  border-radius: 0.5rem;
}

.search-container {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #D3D2D9;
  font-weight: 700;
  padding: 0.5rem;
}

.search-container input {
  border: none;
  outline: none;
  width: 100%;
  color: #171719;
}


.search-container input::placeholder {
  color: #767380 !important
}
.label-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
}
.organization-info-container {
  padding-top: 1.25rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1.25rem;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid #ced4da;
  width: 100%;
  background-color: #f5f5f7;
}

.organization-info-label {
  text-wrap: nowrap;
  color: #4f6c7c;
  font-weight: 400;
  font-size: 14px;
}
.organization-info-data {
  color: #12263f;
  font-size: 14px;
  font-weight: 400;
  text-wrap: nowrap;
}
.text-link-file {
  text-decoration: underline;
  cursor: pointer;
  color: #0050c3;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
.text-link-label {
  color: #525059;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: left;
}
@media (max-width: 768px) {
  .organization-owner-style {
    margin-bottom: 0.5rem;
  }
  .organization-info-icon {
    display: none;
  }
.value-style-container {
  border-left: 1px solid #2075f4;
}
.value-style-label {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #4f6c7c;
}
.value-style {
  font-family: "Roboto";
  font-size: 20px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #12263f;
}
.justification-file-label {
  color: #12263f;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
}
#trow-transfers {
  cursor: default !important;
}

#transfer-transactions .badge {
  min-width: 60px;
}

#transfer-transactions .rdt_TableHead .rdt_TableCol:nth-child(3) .rdt_TableCol_Sortable {
  justify-content: center !important;
}

#transfer-transactions .rdt_TableHead .rdt_TableCol:nth-child(4) .rdt_TableCol_Sortable {
  justify-content: flex-end !important;
}

#transfer-transactions .rdt_TableHead .rdt_TableCol:nth-child(5) .rdt_TableCol_Sortable {
  justify-content: center !important;
}

#transfer-transactions .rdt_TableRow .rdt_TableCell:nth-child(5) {
  justify-content: center !important;
}

#receipt-transfer {
  width: 450px;
  max-width: 95%;
}

#receipt-transfer .close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

#receipt-content {
  font-size: 0.75rem;
  padding: 1rem;
}

#receipt-transfer h4 {
  font-size: 1rem;
  font-weight: bold;
}

.receipt-header {
  text-align: center !important;
}

.receipt-header h5 {
  font-size: 0.9rem;
  font-weight: bold;
}

.receipt-body span:first-child {
  font-weight: bold;
}

.select-sector {
  font-size: 15px;
}

.sector-item {
  padding: 0.5rem 1rem;
}

.sector-item:hover {
  background-color: #e9ecef;
  cursor: pointer;
}

.card-span {
  margin-bottom: 5px;
}

.icon-white svg {
  color: blue !important;
}

.warning-box {
  border: 1px solid #F3AF00;
  background-color: #FFF7E4;
  font-size: 14px;
  border-radius: 8px;
}

.warning-box-link {
  text-decoration: underline;
}

.select-transfers {
  border: 1px solid #D3D2D9;
  padding: 0.5rem 1rem;
}

@media (max-width: 768px) {
  .card-subtitle-mobile {
    display: flex;
    justify-content: center;
  }

  .download-icon {
    stroke: #0050C3;
  }

  .title-card {
    background-color: white !important;
    border: 1px solid #B7B6BF !important;
  }

  .button-contained-3 {
    align-self: center;
    height: auto;
  }

  .tooltip-absolute:hover {
    position: absolute;
  }

  .select-transfers {
    width: 100%;
  }

  .button-contained-3 {
    width: 100%;
  }

  .select__placeholder {
    font-size: 0.75rem;
  }

  .download-icon {
    stroke: #171719;
  }

  .space-form {
    margin-bottom: 0.5rem;
  }
}

.status-badge {
  padding: 4px 8px;
  grid-gap: 1rem;
  gap: 1rem;
  border-radius: 24px;
  font-weight: bold;
}

.requested {
  color: #600EA3;
  background: #F3ECF8;
}

.review {
  color: #694C00;
  background: #FFEAB5;
}

.denied {
  color: #730A46;
  background: #FFE1D7
}

.approved {
  color: #0050C3;
  background: #C8E6FA;
}

.paid {
  color: #00422A;
  background: #CEF2E5;
}

.canceled {
  color: #3A3940;
  background: #EAE9ED;
}
.totalizer-transfer-card {
  height: auto;
  border-radius: 8px;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  justify-content: left;
  flex-direction: column;
  background-color: white;
  padding: 16px 20px 16px 20px;
  border: 1px solid #EDF2F9;
}
.totalizer-transfer-card-value {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #12263f;
  text-align: left;
}

.primary-color-value{
  color: #1C70E9;
}

.totalizer-transfer-card-title {
  color: #12263f;
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.custom-card-subtitle {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  color: #767380;
}

.first-skeleton {
  width: 50% !important;
}

@media (max-width: 768px) {
  .totalizer-transfer-card {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .totalizer-transfer-card-value {
    text-align: center;
  }
  .totalizer-transfer-card-title {
    text-align: center;
  }

  .custom-card-subtitle {
    text-align: center;
  }
  .first-skeleton {
    width: 100% !important;
  }
}

.dotsMobile { 
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-content: flex-end !important;
  justify-content: center !important;
  color: #95aac9 !important;
  background: transparent !important;
  border: none !important;
  cursor: pointer !important;
}

.edit-text{
  color: #767380
}
.input{
    border: 1px solid  hsl(0, 0%, 80%);
    border-radius: 4px;
}

.col-4 {
    padding-right: 0px !important
}
.button-delete{
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.button-delete svg{
  color: #dc3545;
  font-size: 1.5rem;
  cursor: pointer;
}
.button-delete svg:hover{
  color: #bb1929;
}
.button-group{
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
#pdvs-manager .custom-switch{
  margin-top: 0.5rem;
}
#pdvs-manager .form-group{
  margin-bottom: 0.5rem;
}
#pdvs-manager label{
  font-size: 0.8rem;
  color: #506d7c;
}
#pdvs-manager button, #pdvs-manager input{
  font-size: 0.8rem;
  height: 38px;
}
#pdvs-manager input:disabled{
  color: #6e84a3;
}
#pdvs-manager p{
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.custom-control.custom-switch {
  position: static;
}
#config h6 {
  margin-top: 50px;
  margin-bottom: 30px;
  color: #3f25a8;
}

#config > div > div > p {
  font-size: 12px;
  color: #484848;
  margin-bottom: 0;
  padding-left: 5px;
}
#config > div > div > p > span.tips > img.imagem-quest {
  width: 20px;
  padding-left: 5px;
}
#config > div > div > .color-card > .input-card {
  width: 80%;
  border: none;
  color: #989898;
  text-align: right;
}
#config > div > div > .color-card > .input-card:focus {
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

#config > div > div > .color-card > .radio-inline-left {
  font-size: 12px;
  position: absolute;
  width: 50%;
  margin-top: 0.3rem;
}

#config > div > div > .color-card > .radio-inline-right {
  font-size: 12px;
  position: absolute;
  right: 10px;
  margin-top: 0.3rem;
}

#config > div > div > .color-card > .radio-inline-left > input,
#config > div > div > .color-card > .radio-inline-right > input {
  vertical-align: middle;
  margin-right: 5px;
}

#config .pseudo-color {
  display: inline-block;
  width: 35px;
  height: 35px;
  border: solid 1px grey;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  cursor: pointer;
  margin-bottom: 0;
}
#config .color-card {
  border-radius: 10px;
  display: flex;
    align-items: center;
}
#config .color-picker {
  position: absolute;
  left: 15px;
  height: 35px;
  width: 35px;
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}
#config .color-text {
  position: absolute;
  left: 60px;
  width: 100px;
  border: none;
  font-size: 0.8rem;
  color: #12263f;
  background: transparent;
  outline: 0 !important;
}
#config label{
  font-size: 0.7rem;
}
#config .tips{
  margin-left: 0.5rem;
}

#config
 
  > div
  > div
  > .color-card
  > .input-card::-webkit-outer-spin-button,
#config
 
  > div
  > div
  > .color-card
  > .input-card::-webkit-inner-spin-button,
#config > div > div > .color-card > .cor::-webkit-outer-spin-button,
#config > div > div > .color-card > .cor::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#config > div > div > .color-card > .input-card[type="number"],
#config > div > div > .color-card > .cor[type="text"] {
  -moz-appearance: textfield;
}

#config > div > div > .color-card > span {
  position: absolute;
  right: 10px;
  font-size: 20px;
  color: #989898;
}
#config > div > div > .color-card.money > .input-card {
  position: absolute;
  right: 0;
  padding-right: 10px;
}
#config > div > div > .color-card.money > span.money {
  position: absolute;
  left: 10px;
  font-size: 16px;
  color: #989898;
}
.input-pay-method{
  height: 40px;
  display: flex;
  align-items: center;
}
.input-pay-method label{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.input-pay-method label input{
  margin-right: 0.2rem;
}
.input-pay-method .radio-inline-right{
  margin-left: 1rem;
}
.preferences h6, p {
  margin: 0;
}

.section-header {
  padding: 1rem 0;
}

.preferences-content{
  padding: 1rem 10rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.preferences-header .page-title{
  background-color: #FFFFFF !important;
  border-top: 1px solid #D3D2D9;
  border-bottom: 1px solid #D3D2D9;
  box-shadow: none !important;
}

.preferences-header .page-title h2 {
  color: #171719;
}

.preference-select {
  width: 100%;
}

.preferences .card{
  margin: 0;
  border: 1px solid #D3D2D9;
}

.preferences .mt-2{
  margin: 0 !important;
}

/* CardContent */

.preferences-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.preferences-card-content p {
  color: #525059;
  font-size: 14px;
}

.preferences-card-content-text {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.preferences-card-content-child {
    max-width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.preferences-card-content-icon svg {
  width: 24px;
  height: 24px;
}

.preferences-card-content-info {
    display: inline-flex;
    align-items: center;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    max-width: 70%;
}

@media (max-width: 768px) {
  .preferences-content{
    padding: 0 1rem;
    font-size: 0.85rem;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .preferences-card-content {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .preferences-mobile-wrapper {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .preferences-card-content-child {
    max-width: unset;
  }
}
#line-nomenclature{
    height: 50px;
}

#nomenclature-type{
    color:#4F6C7C ;
}

.mobile-buttons {
    z-index: 1 !important;
}
.termo-atual{
    
}

div#card-example{
  background-color: white;
  margin-top: 5px;
  box-shadow: black 5px;
}
#autoajus{
  margin: 0 auto;

}
#titl-example {padding: 5px;
}
#table-caixa table .caixa-link{
    color:#2075F4 !important;
    cursor: pointer;
    text-decoration: underline;
}
#table-caixa table .caixa-link:hover{
    color:#0c25b6 !important;
    cursor: pointer;
    text-decoration: underline;
}
#table-caixa table .caixa-reversal{
    color: red !important;
}
.type-button {
  border-bottom: 3px solid #e1edff;
  padding: 0 0.5rem;
  cursor: pointer;
  color: #4f6c91;
  font-size: 0.95rem;
  text-align: center;
}

.type-button:hover {
  border-color: #66b0ff;
  color: #0050c3;
  opacity: 0.9;
  transition: opacity 250ms ease-in;
}

.type-button.active {
  border-color: #0050c3;
  color: #0050c3;
  transition: border-color 350ms ease-in;
}

.button-delete{
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}
.button-delete svg{
  color: #dc3545;
  font-size: 1.5rem;
  cursor: pointer;
}
.button-delete svg:hover{
  color: #bb1929;
}
#pdvs-manager .custom-switch{
  margin-top: 0.5rem;
}
#pdvs-manager .form-group{
  margin-bottom: 0.5rem;
}
#pdvs-manager label{
  font-size: 0.8rem;
  color: #506d7c;
}
#pdvs-manager button, #pdvs-manager input{
  font-size: 0.8rem;
  height: 38px;
}
#pdvs-manager input:disabled{
  color: #6e84a3;
}
#pdvs-manager p{
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.time-course-button {
  border-bottom: 1.8px solid #e1edff;
  padding: 0 0.5rem;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.825rem;
}

.time-course-button:hover,
.time-course-button.active {
  border-color: #0050c3;
}


.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background-color: #f5f8f8; 
  padding: 10px; 
  z-index: 9999; 
}

#card-checkin {
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    margin-top: 10px;
    background: #183153 !important;
    color: #f8fafc;
}

#card-checkin .total {
    width: 100%;
    padding-right: 20px;
    text-align: right;
    background: transparent;
    border: none;
    color: #f8fafc;
}

#card-checkin span {
    padding-left: 18px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.card .card-header {
    padding: 0.5rem 1.5rem;
}

.card-header .col-md-6:last-child {
    border-left: none;
}

.card .card-header .input-custom {
    font-size: unset;

    color: #4F6C7C;
}

/* .card .card-header .card-search .input-custom::-webkit-input-placeholder{
    color:#b1c2d9 !important;
    opacity:1
} */

.card .card-header .card-search .input-custom::placeholder {
    color: #b1c2d9 !important;
    opacity: 1
}

.card .checked_TableHead .checked_TableCol .checked_TableCol_Sortable {
    color: #95aac9 !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .7rem;
}

.dataTable-th, .checked_TableHeadRow .checked_TableCol_Sortable {
    color: #95aac9;
}

.dataTable-th, .checked_TableHeadRow .checked_TableCol_Sortable {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    font-size: 12px;
    font-weight: 700;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
}

.nameReport {
    display: inline-flex;
    /* -webkit-box-align: center; */
    align-items: center;
    height: 100%;
    line-height: 1;
    -webkit-user-select: none;
            user-select: none;
    color: rgb(79, 108, 124);
}

.checkedTable {
    position: relative;
    width: 100%;
    border-radius: inherit;
    overflow: auto hidden;
    min-height: 0px;
}

.tableReportCheck {
    position: relative;
    width: 100%;
    display: table;
}

.checkLine {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100%;
    color: rgb(79, 108, 124);
    background-color: rgb(255, 255, 255) !important;
}

.card .checked_TableHead {
    font-size: .625rem !important;
    background-color: #f9fbfd !important;
}

.lineReport {
    display: flex;
    text-align: left;
}

.doqUdF, .checkLine, .titleReport, .gibaWW, .heAbaq, .lineReport {
    background: transparent;
}

.doqUdF, .checkLine, .gibaWW, .heAbaq, .lineReport, .line-categorie {
    background: transparent;
}

.doqUdF, .checkLine, .gibaWW, .heAbaq, .lineReport, .line-categorie {
    background: #fff !important;
}

.titleReport {
    display: flex;
    /* -webkit-box-align: stretch; */
    align-items: stretch;
    width: 100%;
    background-color: rgb(255, 255, 255) !important;
    min-height: 56px;
    border-bottom: 1px solid rgb(233, 242, 242);
}

.card .titleReport {
    border-left: 10px solid #edf2f9 !important;
}

.doqUdF, .checkLine, .titleReport, .gibaWW, .heAbaq, .lineReport {
    background: transparent;
}

.card .checked_TableHead .checked_TableHeadRow {
    border-radius: 0;
    border: 0;
    min-height: 48px;
}

.titlesTableChecked {
    position: relative;
    display: flex;
    /* -webkit-box-align: center; */
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    font-size: 12px;
    font-weight: 500;
    color: rgb(79, 108, 124);
    padding-left: 16px;
    padding-right: 16px;
    /* -webkit-box-flex: 1; */
    flex: 1 0;
    max-width: 100%;
    min-width: 100px;
}

.dataTable-th, .checked_TableHeadRow .checked_TableCol_Sortable {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    font-size: 12px;
    font-weight: 700;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    max-width: 100%;
    min-width: 100px;
}

.card .checked_TableHead .checked_TableCol .checked_TableCol_Sortable {
    color: #95aac9 !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: .7rem;
}

.nameCheck {
    display: inline-flex;
    /* -webkit-box-align: center; */
    align-items: center;
    height: 100%;
    line-height: 1;
    -webkit-user-select: none;
            user-select: none;
}

.reportLine {
    display: flex;
    flex-direction: column;
}

.card .checked_TableBody .checked_TableRow {
    border-top: 1px solid #edf2f9 !important;
    border-bottom: 0 !important;
    border-left: 0;
    margin-top: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}

.checkList:not(:last-of-type) {
    border-bottom: 1px solid rgb(233, 242, 242);
}

.checkList:hover {
    border-left-color: #8f44dc;
}

.checkList:not(:last-of-type) {
    border-bottom: 1px solid #e9f2f2;
}

.checkList {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    color: rgb(79, 108, 124);
    background-color: rgb(255, 255, 255);
    min-height: 48px;
}

.card .checked_TableBody .checked_TableRow .checked_TableCell.row-active:first-child {
    border-left-color: #8f44dc !important;
}

.card .checked_TableBody .checked_TableRow .checked_TableCell:first-child {
    border-left: 10px solid #edf2f9 !important;
}

.card .checked_TableRow .checked_TableCell {
    color: #12263f;
}

.lineLit {
    position: relative;
    display: flex;
    /* -webkit-box-align: center; */
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    word-break: break-word;
    /* -webkit-box-flex: 1; */
    flex: 1 0;
    max-width: 100%;
    min-width: 100px;
    font-weight: 400;
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    /* left: 1.5rem; */
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50%/50% 50% no-repeat;
}

button, input {
    overflow: visible;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.custom-control-label {
    cursor: pointer;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-control-label::before {
    background-color: #f3f6f9;
    border: 1px solid #c9cdd0;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.card .checked_TableRow .checked_TableCell {
    color: #12263f;
}


.checkedLineCenter {
    position: relative;
    display: flex;
    /* -webkit-box-align: center; */
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    /* word-break: break-word;
    -webkit-box-flex: 1; */
    flex: 1 0;
    max-width: 100%;
    min-width: 100px;
    font-weight: 400;
}

.checkedLineCenter div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card .checked_TableRow .checked_TableCell {
    color: #12263f;
}

.lineLitValue {
    position: relative;
    display: flex;
    /* -webkit-box-align: center; */
    align-items: center;
    box-sizing: border-box;
    line-height: normal;
    padding-left: 16px;
    padding-right: 16px;
    /* word-break: break-word;
    -webkit-box-flex: 1; */
    flex: 1 0;
    max-width: 100%;
    min-width: 100px;
    font-weight: 400;
    justify-content: flex-end;
}
.powerbi-content {
  height: 100vh;
}

#toggle-switch {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-end;
}
#amount-tickets-label::before {
  border-color: #600ea3 !important;
  background-color: #600ea3 !important;
}
#customSwitchGraph:checked ~ .custom-control-label::before {
  border-color: #0050c3 !important;
  background-color: #0050c3 !important;
}
#customSwitchGraph:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #600ea3 !important;
  background-color: #600ea3 !important;
}

/* #bar-chart{
    width: 100% !important;
} */
#chart-content {
  width: 100% !important;
  overflow-x: auto;
}

#total-value-label {
  margin-right: 45px;
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
}
#amount-tickets-label {
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
}

.modal-options-container {
  position: absolute;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  border: 1px solid rgba(18, 38, 63, 0.1);
  border-radius: 0.375rem;
  background-color: #fff;
  color: #6E84A3;
  padding: 0.5rem 0;
  margin-right: 5rem;
  margin-top: 8.5rem;  
}
.dropdown-item {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}
.dropdown-item svg {
  width: 1.125rem;
  margin: 0;
}

.last-item{
  margin-top: 3rem;  
}
.custom-badge-success {
  color: #008656;
  background-color: #d6f6ea;
}
.custom-badge-danger {
  color: #a62930;
  background-color: #fde0e1;
}
.custom-badge-warning {
  color: #a57700;
  background-color: #fdf2d6;
}

.custom-badge-custom {
  color: #525059;
  background-color: #eae9ed;
  font-size: 12px !important;
  font-weight: 700 !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0.5em !important;
}

.custom-badge-marketing {
  color: #004b78;
  background-color: #d7faf0;
  font-size: 12px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0.5em !important;
  font-weight: 700 !important;
}

.custom-badge-info {
  color: #004b78;
  background-color: #e6fcff;
  font-size: 12px !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  padding: 0.25em !important;
  font-weight: 700 !important;
}

.transfer-badge {
  font-family: "Lato";
  font-size: 10px !important;
  font-weight: 700 !important;
  line-height: 10px !important;
}

.transfer-badge-success {
  background-color: #cef2e5;
  color: #00422a;
}
.transfer-badge-warning {
  background-color: #ffeab5;
  color: #694c00;
}
.transfer-badge-danger {
  background-color: #ffe1d7;
  color: #730a46;
}
.transfer-badge-light {
  background-color: #eae9ed;
  color: #3a3940;
}

.courtesy-form {
  font-family: "Lato" !important;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: #171719 !important;
}

.custom-label {
  color: #171719 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 21px !important;
}

.courtesy-form .required::first-letter {
  color: red;
}

.loading{
  margin-top: 5px;
}

.form-control.custom-input-form::placeholder {
  color: #767380 !important;
}

form {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

#courtesy-switch {
  margin-top: 0.5rem;
}

#courtesy-switch p {
  color: #171719 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 21px !important;
}

#courtesy-switch .tooltip-absolute:hover{
  position: relative;
}

.promoter-selector {
  margin-top: -1.5rem;
}
.react-switch-bg > div{
    display: flex ;
    justify-content: center ;
}

.switch-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Body--open {
  height: 100%;
  overflow: hidden;
}

.img-group {
  border-radius: 0.25rem;
  border: 1px dashed #5b4299;
}
.dropzone-wrapper {
  position: relative;
}

.dropzone-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  font-weight: bolder;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 1.5rem 1.25rem;
}

.icon-container {
  background-color: #e6eef9;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  align-self: center;
}

@media (max-width: 768px) {
    .dropzone-banner {
        height: 10rem;
    }

    .dropzone-thumb {
        height: 0;
        padding-bottom: 80%;
        padding-top: 2rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    .dropzone-thumb .img-preview {
        margin-top: 70%;
    }

    .thumb {
        padding-top: 100%;
    }
  .dropzone-banner {
    height: 10rem;
  }
  .dropzone-thumb {
    height: 0;
    padding-bottom: 80%;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .dropzone-thumb .img-preview {
    margin-top: 70%;
  }
  .thumb {
    padding-top: 100%;
  }
}

@media (max-width: 425px) {}

.dropcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dropcard-button {
  border: none;
  background-color: white;
  color: black;
}

.dropcard-buttons {
  display: flex;
  flex-direction: row;
}

.dropcard-content {
  display: flex;
  flex-direction: row;
}

.dropcard-title {
  font-weight: bold;
  color: #3a3940;
}

.dropcard-size {
  color: #525059;
}

.dropcard .icon-container {
  margin-right: 2rem;
}

.title-completition p {
  color: #1c70e9;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
}
.title-completition {
  margin-top: -0.5rem;
}
.title-completition p {
  margin: 0;
  color: #1c70e9;
  font-weight: 400;
  line-height: 1.125rem;
  font-size: 0.75rem;
}

.title-completition .file-type {
  margin-top: 0.5rem;
  color: #767380;
  line-height: 1.125rem;
  font-weight: 400;
  font-size: 0.75rem;
}

/* .pdv-sales-filter-container {
    margin-top: 39px;
  }
  
  .pdv-sales-filters {
    padding: 13px 14px;
  } */

.pdv-sales-filters div:last-child {
  margin-right: 0;
}

.pdv-sales-filters .apply-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
}

.pdv-sales-filters .filter-counter {
  background: #2075f4;
  font-family: Roboto, sans-serif;
  border-radius: 100%;
  font-size: 0.7rem;
  color: #ffffff;
  width: 11px;
  height: 11px;
  text-align: center;
  position: relative;
  top: -8px;
  left: -7px;
  padding: 3px;
}

.desktop-table-overflow {
  overflow-x: auto;
}

.table-small-desktop {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-courtesy {
  min-width: 140rem;
  border: none;
}

.table-header-courtesy {
  background-color: #f7f7f9;
  font-family: "Lato";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-transform: uppercase;
  height: 2rem;
}

.table-row-courtesy {
  height: 2rem;
  font-family: Lato;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-bottom: 1px solid #f7f7f9;
}

.table-cell-courtesy {
  color: #3a3940;
  max-width: 15rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell-courtesy:hover {
  word-wrap: break-word;
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
}

.table-cell-courtesy-actions {
  padding-top: 0.5rem;
}

.courtesy-button-outline {
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  border-radius: 0.25rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: 1px solid #0050c3 !important;
  color: #0050c3 !important;
}
.courtesy-button-outline:hover {
  background-color: rgba(0, 80, 195, 0.1) !important;
}

.custom-confirm-button-outline:active {
  background-color: rgba(95, 165, 235, 0.3) !important;
}

@media (max-width: 768px) {
  .courtesy-button-outline {
    width: 100%;
  }
  .courtesy-button-contained {
    width: 100%;
  }
}

.alert-loading {
  width: 5.5rem;
  height: 5.5rem;
}

.alert-title {
  color: var(--neutral-color-dark-d-3, #3A3940);
  text-align: center;
  font-family: Lato;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4rem;
}

.alert-text {
  color: var(--neutral-color-dark-d-4, #525059);
text-align: center;
font-family: Lato;
font-size: 1rem;
font-style: normal;
font-weight: 400;
line-height: 1.5rem;
}

.alert-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 1rem 0;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert-content svg {
  animation: spin 1s linear infinite;
}

.cell-quantity {
  text-align: right;
  padding-right: 1.5rem;
}

.filter-icon-container {
  display: inline-flex;
}

.filter-counter-courtesies {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2075f4;
  color: #ffffff;
  border-radius: 50%;
  position: relative;
  font-size: 0.65rem;
  height: 1rem;
  width: 1rem;
  top: -5px;
  left: -11px;
}

.sectors {
  list-style-type: none;
}
.card-sector {
  height: 60px;
  padding-left: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-left: 4px solid #95aac9;
  color: #95aac9;
}
.card-sector:hover {
  border-left: 4px solid #2c7be5;
  color: #2c7be5;
  transition: 0.2s;
  margin-bottom: 1.5rem;
  box-shadow: 4px 2px 5px 0px rgba(18, 38, 63, 0.3);
}
.card-sector.dragging {
  border-left: 4px solid #2c7be5;
  color: #2c7be5;
  box-shadow: 4px 2px 5px 0px rgba(18, 38, 63, 0.3);
}
.card-sector-name {
  color: #2075f4;
  font-size: 18px;
}

#subheader .nav-tabs {
  border-bottom-color: #e3ebf6;
}
#subheader .nav-link{
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid transparent;
  margin-right:0.5rem;
  color: #95aac9;
  font-size: 0.8rem;
  cursor: pointer;
}
#subheader .nav-item:last-child .nav-link{
  margin-right:0;
}
#subheader .nav-link:hover{
  color: #6e84a3;
}
#subheader .nav-link.active{
  border-bottom-color: #2c7be5;
  color: #12263f;
}
.overlay-container-modal {
  width: 100%;
  position: fixed;
  display: flex;
  inset: 0px;
  z-index: 21000;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.container-modal {
  width: 42.75rem;
  border: 1px solid rgb(204, 204, 204);
  background-color: rgb(255, 255, 255) !important;
  border-radius: 0.25rem;
  outline: none;
  padding: 1rem 1.5rem 1rem 1.5rem;
  margin: auto;
}

@media (max-width: 768px) {
  .container-modal {
    width: 100vw;
  }
}

.integration-header-container {
  border-bottom: 1px solid #eae9ed;
  padding: 0 0.5rem 1rem 0.5rem;
}
.integration-header-title {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #767380;
}
.close-icon-integration {
  cursor: pointer;
}
.integration-body-title {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #2f2e33;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.new-label {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #2f2e33 !important;
}

.valid-information-text{
  margin-top: 5px;
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #2F2E33;
}

.integration-label-input {
  font-family: "Lato";
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #2f2e33 !important;
}
.form-control.integration-field-custom::placeholder {
  color: #767380 !important;
}
.form-control.integration-field-custom:hover {
  border: 1px solid #00a5e3 !important;
}
.characters-integration-input {
  font-family: "Lato";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: right;
  color: #767380;
}
.integration-radiobutton-container {
  grid-gap: 1rem;
  gap: 1rem;
}
.integration-label-info {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #525059;
}

.integration-name-field-error {
  font-size: 0.8125rem;
  color: #e63757;
  font-weight: 400;
  line-height: 1.5;
}

.integration-action-type-field:disabled {
  background-color: transparent;
}
.form-control.integration-action-type-field:disabled::placeholder {
  color: black !important;
}
.form-control.integration-action-type-field:focus {
  border-color: #ced4da !important;
}
.form-control.integration-action-type-field:hover {
  border-color: #ced4da !important;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .container-modal:has(.custom-integration-body) {
    width: 100%;
    position: fixed;
    bottom: 0;
    max-height: 100%;
    overflow-y: auto;
  }
}

.modal-background{
    display: none;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }
  .modal-background.show {
    display:block;
    }
  .modal-center {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 600px;
    animation: animatetop 1s;
    height: auto;
  }

  .html2canvas-container {
    width: 100%;
}


  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  #print_to_pdf.receipt-header {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #print_to_pdf.receipt-check {
      color: #72bf45;
      font-size: 28px;
      display: flex;
      justify-content: center;
      padding-top: 10px;
      align-items: center;
      font-weight: bold;
  }

  #print_to_pdf.receipt-logo {
      width: 40% !important;
  }

  #print_to_pdf.receipt-body {
      display: flex;
      justify-content: center;
      align-items: center;
  }


  #print_to_pdf.receipt-text-1 {
    font-size: 18px;
    color: #2e2e2e
  }

  #print_to_pdf.receipt-text-2 {
    font-size: 18px;
    color: #3a89c5
  }

  #print_to_pdf.receipt-title-1 {
    color: #999999;
    font-weight: bold;
    font-size: 18px;
}

#print_to_pdf.receipt-border {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    border-bottom: 3px dashed #999999;
}


  /* Add Animation */
@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
  }

#modal-order .table td {
  font-weight: normal;
  color: #6e84a3 !important;
}
#modal-order .card-header-title {
  color: #6e84a3;
  font-size: 0.825rem;
  font-weight: bold;
}
.subRow {
  border: 2px solid #3f25a8;
}
.margin0 {
  margin: 0 !important;
}
.qCZjB:hover:not(:disabled) {
  background: none;
}
.iuCFTz {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.card .gAuPhq {
  border-radius: 0px !important;
}

.modal-participantes {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  /* z-index: 10000; */
  background-color: white !important;
  transition: 0.5s;
}

.modal-participantes::-webkit-scrollbar {
  width: 10px;
}

.modal-participantes::-webkit-scrollbar-track {
  background: #fff;
}

.modal-participantes::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  border: 3px solid #600ea3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.left-modal-participantes {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 50000;
  background-color: white;
  transition: 0.5s;
}

.left-modal-participantes .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-modal-participantes .card-header h4 {
  font-weight: bold;
}

.modal-participantes-visible {
  display: initial;
}
.modal-participantes .card-body {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.modal-participantes .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-participantes .card-header h4 {
  font-weight: bold;
}

.editaParticipante {
  position: absolute;
  inset: 50% auto auto 50%;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255) !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  margin-right: -50%;
  transform: translate(-40%, -60%);
}

@media screen and (max-width: 992px) {
  .modal-participantes {
    width: 100%;
    height: 100%;
    right: 0;
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .modal-participantes.modal-participantes-hidden {
    right: -100% !important;
  }

  .editaParticipante {
    transform: translate(-50%, -50%) !important;
  }
}

@media screen and (min-width: 924px) {
  .gAuPhq {
    overflow-x: hidden !important;
  }
}
.comprador-detail p {
  color: #4f6c7c;
  font-size: 1em;
}
.participantes-header {
  background-color: #f9fbfd !important;
  padding: 10px 0;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.8rem;
}
.participante-detail {
  margin-top: 5px;
}
.payment-icon {
  width: 25px !important;
  margin-right: 0.5rem;
}
button.btn-red-border {
  background-color: transparent !important;
  border: 2px solid #e63757 !important;
  color: #183153;
}
.btn-red-border svg {
  color: #e63757;
}
button.btn-red-border:hover {
  color: #e63757;
}
.sale-btn {
  display: flex;
  justify-content: space-around;
}

.sales-details {
  border-bottom: 1px solid #edf2f9;
  padding-bottom: 13px;
}
.sales-details p {
  font-size: 0.8rem;
  margin: 0;
}
.sales-details p b {
  color: #6e84a3;
}
.link-whatts {
  color: #128c7e;
  text-decoration: underline;
}
.link-whatts:hover {
  color: #094740;
}

@media (max-width: 768px) {
  .sale-btn {
    margin-left: 15px;
    width: 30%;
  }
}

.input {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
}

.custom-button{
  text-decoration: none !important;
  background-color: transparent !important;
  border: none !important;
  color: #0050C3 !important;
}

.col-4 {
  padding-right: 0px !important;
}

.modal-header-text {
  color: #2f2e33;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.label-input {
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #525059;
}

.characters-remaining {
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: right;
  color: #767380;
}

.checkbox-label {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #3a3940;
}

.checkbox-setup {
  width: 0.8rem !important;
  height: 0.8rem !important;
}

.submit-button-container {
  height: 280px;
}
.close-area {
  position: relative;
}
.close-btn {
  position: absolute;
  right: -1.6rem;
  top: 0.6rem;
}
@media screen and (max-width: 768px) {
  .submit-button-container {
    height: 355px;
  }
}

.btn-checkin {
  width: auto !important;
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
}

.btn-checkin:hover {
  background-color: #2569c3 !important;
  border-color: #2569c3 !important;
}

#modal-sectors {
  width: 450px;
  max-width: 95%;
}

.config-tag-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: #12263f;
  margin-bottom: 4px;
}

.config-tag-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #4f6c7c;
}

.config-tag-label {
  color: #12263f;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  margin-bottom: 4px;
}

.card-preview-tag {
  background-color: #edf2f9;
  min-width: 724px;
  min-height: 180px;
  border-radius: 8px;
  padding: 16px;
}

.preview-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #4f6c7c;
}

.tag-preview {
  min-width: 386.89px;
  min-height: 116.48px;
  grid-gap: 4px;
  gap: 4px;
  background-color: #fff !important;
  box-shadow: 0px 5.46px 21.84px 0px #00000014;
}

.tag-preview.preview-without-shadow {
  box-shadow: none;
}

#custom-tag-card {
  max-width: 724px;
}

.card-alert {
  width: 449px;
  height: 120px;
  background: rgba(205, 250, 255, 0.5) !important;
  border: none;
}

.card-alert-title {
  color: #004b78;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.card-alert-text {
  color: #3a3940;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.swal2-cancel {
  color: #0050c3;
  background-color: transparent;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  border: 1px solid #0050c3;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
}

.swal2-confirm {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  border: 1px solid #f53c5a;
  background-color: #f53c5a;
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  margin-left: 1rem;
}

@media screen and (max-width: 768px) {
  #page-participants .ktMXMg {
    display: block;
  }

  .btn-checkin span {
    display: none;
  }

  #page-participants .uSaoC:nth-child(3) {
    display: none;
  }

  #page-participants .rdt_TableRow .rdt_TableCell:nth-child(3) {
    display: none;
  }

  #details-code-user {
    cursor: pointer;
  }

  .drag-vertical {
    transform: rotate(90deg);
  }

  .card-alert {
    width: 100%;
    height: 160px;
    background: rgba(205, 250, 255, 0.5) !important;
    border: none;
  }

  .swal2-confirm,
  .swal2-cancel {
    width: 100%;
    margin: 0 0 0.755rem 0;
  }
}

.details-code-participantes {
  cursor: pointer;
  height: 55px;
}

.checkin_result {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  z-index: 10;
}

.checkinSuccess {
  background-color: #00d97e;
}

.checkinError {
  background-color: #e63757;
}

.checkin_result .checkin_content {
  text-align: center;
  margin-top: -50px;
}

.checkin_result .checkin_content svg {
  font-size: 5rem;
  color: white;
  margin-bottom: 20px;
}

.checkin_result h1 {
  color: #fff;
  font-weight: 100;
  text-align: center;
  font-size: 1.5rem;
  padding: 0 1rem;
}

.checkin_result button {
  background-color: transparent;
  border: 2px solid white;
  border-radius: 100px;
  font-size: 1.5rem;
  padding: 0.25rem 2rem;
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 120px;
  position: absolute;
  color: #fff;
}

#camera_bg,
#input_bg {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* CAMERA BG */
#camera_bg {
  background: #4f6c7c;
}

#camera_bg #qr_mask #qrmask-frame {
  position: absolute;
  width: 250px;
  height: 250px;
  left: calc(50% - 125px);
  top: calc(50% - 125px);
  border: 4px solid #00a7e5;
  z-index: 10;
}

#camera_bg #qr_mask .qrmask-bg {
  background: #00000085;
  position: absolute;
  z-index: 10;
}

#camera_bg #qr_mask #qrmask-top,
#camera_bg #qr_mask #qrmask-bottom {
  width: 100%;
  height: calc(50% - 125px);
  left: 0;
}

#camera_bg #qr_mask #qrmask-left,
#camera_bg #qr_mask #qrmask-right {
  height: 250px;
  width: calc(50% - 125px);
  top: calc(50% - 125px);
}

#camera_bg #qr_mask #qrmask-top {
  top: 0;
}

#camera_bg #qr_mask #qrmask-bottom {
  bottom: 0;
}

#camera_bg #qr_mask #qrmask-left {
  left: 0;
}

#camera_bg #qr_mask #qrmask-right {
  right: 0;
}

#camera_bg .qr_reader div {
  display: none;
}

#input_bg {
  background: #00a7e5;
}

#btn-inputmode {
  position: relative;
  width: 300px;
  margin: 0;
}

#btn-inputmode input {
  height: 60px;
  padding: 0.25rem 70px 0.25rem 1.5rem;
  font-size: 1.25rem;
}

#btn-inputmode button {
  position: absolute;
  top: 7px;
  right: 1rem;
  width: 45px;
  height: 45px;
  border-radius: 40px !important;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#btn-inputmode button svg {
  margin: 0 !important;
}

#validacao_header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 2rem;
  z-index: 2;
  text-align: center;
}

#validacao_header h2 {
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-top: 1rem;
  color: white;
}

#validacao_header p {
  color: white !important;
  font-size: 0.8rem;
}

#validacao_header svg {
  margin-left: 1rem;
  font-size: 1.5rem;
}

#btn-scanclose {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 3;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  color: white;
  background-color: #98afbc;
  border: none;
}

#btn-scanclose svg {
  margin: 0;
}

#btn-scanmode {
  position: absolute;
  z-index: 3;
  width: 300px;
  left: 50%;
  bottom: 50px;
  margin-left: -150px;
  color: #4f6c7c !important;
  background: white !important;
  font-weight: bold;
  padding: 0.75rem 0;
}

.qr_reader,
.qr_reader section {
  height: 100% !important;
  padding-top: 0 !important;
}

.qr_reader section div {
  box-shadow: rgb(44 123 229) 0px 0px 0px 5px inset !important;
  border: 75px solid rgba(0, 0, 0, 0.3) !important;
}

#page-participants .card-participante button:disabled {
  background: #aab1b9 !important;
  border: none !important;
}

.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.ReactModal__Overlay {
  overflow: auto;
}

.ReactModal__Body--open {
  height: 100%;
  overflow: hidden;
}

.select-info {
  font-weight: 700;
  color: #4f6c7c;
  font-size: 12px;
}

.tag-preview p {
  font-weight: 700;
  color: #000;
}

.print-test,
.print-test:hover {
  color: #2c7be5;
  font-weight: 700;
  border: 1px solid #2c7be5;
}

.save-tag,
.save-tag:hover {
  color: #ffff;
  font-weight: 700;
  width: 94px;
  height: 40px;
  background: #2c7be5;
}

.save-tag:disabled {
  background: #95aac9;
}

.tag-buttons {
  grid-gap: 15px;
  gap: 15px;
}

.tag-fields-items {
  min-width: 80px;
  max-width: 240px;
  min-height: 80px;
}

.print-tag-button {
  background-color: transparent;
  border: none;
}

#print-tag-icon {
  width: 15px;
  height: 15px;
  color: #6e84a3;
}

@media screen and (min-width: 768px) {
  .icon-drag-container {
    padding-top: 2.2rem;
  }
}

@media screen and (max-width: 768px) {
  .card-preview-tag {
    min-width: 100px;
  }

  .tag-preview {
    margin: 8px;
    min-width: 100%;
    background-color: #fff !important;
  }
}

.simpletablecontainer {
  margin: 0 !important;
}

.simpletable {
  background-color: none;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0 !important;
}

.simpletable.table thead {
  background-color: #f9fbfd !important;
}

.simpletable.table thead th {
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: normal;
  color: #98afbc !important;
  padding-top: 21px !important;
  padding-bottom: 21px !important;
  border-top: 2px solid #f0f4fa;
  border-bottom: 2px solid #f0f4fa;
}

.simpletable.table thead th:first-child {
  border-radius: 8px 0 0 0;
  border-left: 2px solid #f0f4fa;
}

.simpletable.table thead th:last-child {
  border-radius: 0 8px 0 0;
  border-right: 2px solid #f0f4fa;
}

.simpletable.table thead tr:hover {
  background-color: #f9fbfd !important;
}

.simpletable.table tbody tr {
  background: #ffffff;
}

.simpletable.table tbody tr:hover {
  background-color: #ebedf1 !important;
}

.simpletable.table tbody .pointer {
  cursor: pointer;
}

.simpletable.table tbody tr.section td {
  background: #D8E2E7;
  color: #8FA5B2 !important;
  font-weight: 500 !important;
  padding-top: 17px !important;
  padding-bottom: 17px !important;
  border-color: #BACAD3;
  border-bottom: 1px solid #BACAD3;
}

.simpletable.table tbody td {
  color: #4f6c7c !important;
  font-weight: 400 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 0.75rem;
  border-top: 1px solid #d8e2e7;
}

.simpletable.table tbody td.no-borders {
  border-top: none;
}

.simpletable.table tbody td.edge-borders {
  border-left: 1px solid #d8e2e7;
  border-right: 1px solid #d8e2e7;
}

.simpletable.table tbody td.edge-borders:first-child {
  border-right: none;
}

.simpletable.table tbody td.edge-borders:last-child {
  border-left: none;
}

.simpletable.table tbody tr:last-child .edge-borders {
  border-bottom: 1px solid #d8e2e7;
}

.simpletable.table tbody tr:last-child .edge-borders:first-child {
  border-radius: 0 0 0 8px;
}

.simpletable.table tbody tr:last-child .edge-borders:last-child {
  border-radius: 0 0 8px 0;
}

.simpletable.table tfoot {
  position: relative;
  top: 32px;
  box-shadow: 0px 4px 13px #0000001a;
}

.simpletable.table .spacer {
  height: 32px;
}

.simpletable.table .footer {
  background: #2075f4;
  box-shadow: 0px 4px 13px #0000001a;
}

.simpletable.table .footer:hover {
  background-color: #2075f4 !important;
}

.simpletable.table .footer td:first-child {
  border-radius: 8px 0 0 8px;
}

.simpletable.table .footer td:last-child {
  border-radius: 0 8px 8px 0;
}

.simpletable.table .footer td {
  color: #ffffff !important;
  font-family: Rubik, sans-serif;
  padding-top: 21px !important;
  padding-bottom: 21px !important;
  font-size: 0.75rem;
  border: 0;
}

.details-info-table.table tbody td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  color: #8FA5B2 !important;
}
.pdv-sales-filter-container {
  margin-top: 39px;
}

.pdv-sales-filters {
  padding: 13px 14px;
}

.pdv-sales-filters div {
}

.pdv-sales-filters div:last-child {
  margin-right: 0;
}

.pdv-sales-filters select {
  border: 1px solid #d8e2e7;
  border-radius: 8px;
  width: 100%;
  color: #98afbc;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  font-size: 0.75rem;
  min-height: 40px;
  padding: 0 10px;
  outline: none;
}

.pdv-sales-filters .date-period-text {
  color: #95aac9;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 0.625rem;
  margin-right: 15px;
}

.pdv-sales-filters .apply-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.95rem;
}

.pdv-sales-filters .filter-counter {
  background: #2075f4;
  font-family: Roboto, sans-serif;
  border-radius: 100%;
  font-size: 0.52rem;
  color: #ffffff;
  width: 11px;
  height: 11px;
  text-align: center;
  position: relative;
  top: -5px;
  left: -11px;
}

.pdv-sales-filters .datetimeinput {
  border: 1px solid #d8e2e7;
  border-radius: 8px;
  width: 100%;
  min-height: 40px;
  font-family: Roboto, sans-serif;
  font-weight: 300;
  color: #98afbc;
  padding: 0 16px;
  font-size: 0.75rem;
  outline: none;
}

.pdv-sales-filters .datetimeinput-icon {
  position: absolute;
  right: 28px;
  color: #98afbc;
  font-size: 1.1rem;
}

.wiki-body h2{
    font-weight: bold;
    font-size: 1rem;
}
.wiki-title{
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
body {
  overflow-x: hidden;
}

.display-4 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.1;
  color: #12263f;
}

.text-muted {
  color: #95aac9 !important;
  font-size: 0.9375rem;
}
.text-mail-sent {
  color: #2F2E33 !important;
  font-size: 14px;
  font-weight: 400;
}
.input-group-prepend {
  font-size: 0.8125rem;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #00d97e;
  background-image: none;
}

.valid-feedback {
  color: #00d97e;
}

.invalid-feedback {
  /* color: #e63757; */
}

.form-group a,
#form-login .nav-link {
  color: #0050c3 !important;
  cursor: pointer;
  font-weight: bold;
}

.form-group a:hover,
#form-login .nav-link:hover {
  color: #0056b3 !important;
}

.valid-feedback,
.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 0.75rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  border-radius: 0.5rem;
}

#page-login .display-4 {
  font-size: 1.5rem;
}

#login-content {
  background-color: white;
  align-items: flex-start;
  justify-content: center;
}

#login-content #login-container {
  width: 500px;
}

#login-content #login-container #eye-icon {
  cursor: pointer;
}

#form-login {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
}

#form-login img {
  padding: 20px;
}

#form-login input {
  height: 46px;
  margin-bottom: 15px;
  padding: 0 20px;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
}

#form-login input::placeholder {
  color: #c2c2c2;
}

#form-login .password-field input {
  width: calc(100% - 45px);
  margin: 0;
  border-right-width: 0;
}

#form-login .password-field .input-group-text {
  border-left-width: 0;
  background: transparent;
  color: #95aac9;
}

#form-login hr {
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #cdcdcd;
  width: 100%;
}

#form-login a {
  font-size: 16;
  font-weight: bold;
}

#form-login #password-recover {
  border: none;
  color: #0050C3;
  margin-bottom: 15px;
  font-size: 14px;
  padding: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  font-weight: 700;
}

#button-submit-login, #button-back-login {
  padding: 8px 16px;
  min-width: 460px;
}

.login-help-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #2F2E33;
}

.login-talk-us {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-underline-position: from-font;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #0050C3;
}

@media screen and (max-width: 991px) {
  #login-bg {
    display: none;
  }

  #login-content {
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
  }

  #form-login {
    margin: 0;
    width: 100%;
  }

  #button-submit-login, #button-back-login {
    min-width: 350px;
  }
}

#login-content .alert {
  display: none;
  justify-content: space-between;
}

#login-content .alert-dismissible .close {
  position: relative;
  font-size: 1rem;
  padding: 0;
  cursor: pointer;
}

#login-content .alert-dismissible .close:hover {
  color: white;
}

#login-content .alert.showelement {
  display: flex;
}

#form-login .register-title {
  color: #0050C3;
  font-size: 1.25rem;
}

#form-login .register-text {
  color: #2F2E33 !important;
  font-weight: 400;
}

.input-icon-container {
  position: relative;
  display: flex;
  width: 100%;
}

.input-icon {
  position: absolute;
  top: 0.75rem;
  left: 0.5rem;
  z-index: 10;
}

.input-icon-load {
  position: absolute;
  top: 0.75rem;
  right: 0.5rem;
  z-index: 10;
  width: 24px;
  height: 24px;
}

#form-login .input-icon-container input{
  padding-left: 35px;
}

#account-create {
  display: inline-flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

#account-create .nav-link {
  padding: 0;
  text-decoration: underline;
}

#login-bg {
  background-image: url(/static/media/image1.adc61dd9.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

.content-header {
  display: flex;
  justify-content: center;
  height: 8rem;
  align-items: center;
  margin: 0px -50px;
}

@media (max-width: 768px) {
  .content-header {
    margin: 0px;
  }
}
.two-factor-buttons {
    grid-gap: 8px;
    gap: 8px;
}

.otp-input-login {
    box-shadow: 0px 4px 16px 0px #00000014;
    border-radius: 8px;
    color: #171719;
    margin: 8px;
}

.otp-input-login:focus-visible {
    outline-color: #0050C3;
}

.text-error-two-factor {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    color: #F53C5A;
}

.button-resend-code {
    font-family: Lato;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: solid;
            text-decoration-style: solid;
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    background-color: transparent;
    border: none;
    color: #171719;
}

.button-resend-code:hover {
    background-color: #EAE9ED;
}

.button-resend-code[disabled] {
    text-underline-position: from-font;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    text-decoration: none;
    background-color: transparent;
    border: none;
    color: #767380;
}
#login-content label {
  font-weight: bold;
  color: #4f6c7c;
  font-size: 12px;
  margin-bottom: -2px;
  margin-top: 10px;
}

#login-content .disabled-contained-grey {
  background-color: #d3d2d9 !important;
  color: black;
}

#login-content button #button-submit-login {
  background-color: #0050c3;
  border: none;
  color: white;
}

#login-content:active button #button-submit-login {
  background-color: #00145f;
}

.content-header {
  display: flex;
  justify-content: center;
  height: 8rem;
  align-items: center;
  margin: 0px -50px;
}

@media (max-width: 768px) {
  .content-header {
    margin: 0px;
  }
}
.seat {
  background-color: white;
  color: #00a7e1;
  text-align: center;
  border: 1px solid;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  border-radius: 0.5rem;
}

.seat-selected {
  background-color: #00a7e1;
  color: white;
}

.seat-disabled {
  background-color: #b7bbbd;
  color: white;
}

/*
 * Note that this is toastr v2.1.3, the "latest" version in url has no more maintenance,
 * please go to https://cdnjs.com/libraries/toastr.js and pick a certain version you want to use,
 * make sure you copy the url from the website since the url may change between versions.
 * */
.toast-title {
  font-weight: 700;
}
.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #fff;
}
.toast-message a:hover {
  color: #ccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}
.toast-close-button:focus,
.toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}
#toast-container * {
  box-sizing: border-box;
}
#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  /* -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999; */
  color: #fff;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
  filter: alpha(opacity=90);
}
#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}
#toast-container > div:hover {
  /* -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000; */
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}
#toast-container > .toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
}
#toast-container > .toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
}
#toast-container > .toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}
#toast-container > .toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
}
#toast-container.toast-bottom-center > div,
#toast-container.toast-top-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
#toast-container.toast-bottom-full-width > div,
#toast-container.toast-top-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.toast {
  background-color: #030303;
}
.toast-success {
  background-color: #00d97e;
}
.toast-error {
  background-color: #e63757;
}
.toast-info {
  background-color: #2c7be5;
}
.toast-warning {
  background-color: #f6c343;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

.tamanho-pagina {
  min-height: calc(100vh - 6vh);
}

#dataIni,
#horaIni,
#dataF,
#horaF {
  border: 0px;
  padding: 0px;
  height: 20px;
  font-size: 12px;
  color: #9b9b9b !important;
  margin-top: -2px;
}

.fonte-label {
  font-family: "Roboto";
  font-size: 15px;
  color: #111 !important;
}
.borda {
  border: 1px solid #969696;
}
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(155, 155, 155, 0.25) !important;
  border-color: #9b9b9b !important;
}
.espacamento-top {
  margin-top: 10vh;
}

.espacamento-top2 {
  margin-top: 2vh;
}
.espacamento-top5 {
  margin-top: 5vh;
}
.inputfile {
  opacity: 0;
  z-index: -1;
}

.inputfile + span + label {
  max-width: 80%;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  margin: 0px;
  border-radius: 25px;
  z-index: 2;
  position: absolute;
  right: 10px;
}
.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
  padding: 0.2rem 1.25rem;
}

.inputfile-6 + label span {
  width: 200px;
  min-height: 2em;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: top;
}

.inputfile-6 + label strong {
  height: 100%;
  width: 100%;
  color: #fff !important;
  background-color: #00a5e3;
  display: inline-block;
  border-radius: 5px;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
  background-color: #00a5e3;
}

@media screen and (max-width: 50em) {
  .inputfile-6 + label strong {
    display: block;
  }
}

.espacamento-top {
  margin-top: 10vh;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
.event-tag {
  background-color: #183153;
  color: white !important;
  margin-left: 10px;
  text-align: center;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.2;
  border-radius: 50px;
}
.event-tag b {
  font-size: 1rem;
}

/* HEADER - EVENT CREATION */
.header {
  margin-bottom: 2rem;
}
.header-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #e3ebf6;
}
.header-title {
  margin-bottom: 0.5rem;
  color: #12263f;
  font-size: 1.625rem;
}
.header-postitle {
  color: #95aac9;
  font-size: 0.85rem;
}
.header-postitle a {
  color: #2c7be5;
  font-weight: bold;
}

/* SIDEBAR - EVENT CREATION */
#event-steps {
  justify-content: flex-end;
  background: white;
  height: 100%;
}
#event-steps .card-header {
  display: flex;
  align-items: center;
}
#event-steps .card-header svg {
  color: #2c7be5;
  margin-left: 1rem;
}
.step-step {
  padding: 1rem;
  border: 1px solid #edf2f9;
  border-left: 5px solid #e2e2e2;
  cursor: pointer;
}
.step-active {
  border-left-color: #2c7be5;
  color: #2c7be5;
  background: #f9fbfd;
}
.step-title {
  flex-wrap: wrap;
  display: flex;
  justify-content: left;
  align-items: center;
}
.step-title span:last-child {
  font-size: 1rem;
  font-weight: bold;
}
small {
  color: #95aac9;
}
.step-bullet {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #95aac9;
  color: #95aac9;
  border-radius: 30px;
  padding: 5px;
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}
.step-active .step-bullet {
  color: #2c7be5;
  border-color: #2c7be5;
}

.transparent {
  opacity: 0.5;
}

#event-accept {
  z-index: 10;
  position: fixed;
  bottom: 0;
  background: #2c7be5;
  color: white;
  box-shadow: 0px -20px 20px white;
  padding: 20px 60px;
}
#event-accept .btn-white-border {
  width: 100%;
  background: transparent;
  border: 2px solid white !important;
}
#event-accept .btn-white {
  width: 100%;
  background: white;
  color: #2c7be5;
  font-weight: bold;
  border: 2px solid white !important;
}
#event-accept .form-check-inline {
  background: none !important;
}
#event-accept label {
  padding: 2px 0 0 24px;
}
#create-event-2 .btn-blue-border {
  color: #00a5e3;
  width: 100%;
  background: transparent !important;
  border: 2px solid #00a5e3 !important;
}
.event-extraspace {
  margin-bottom: 40px;
}

/* STEPS */
.event-step {
  margin-bottom: 4.5rem !important;
}
.event-tags {
  margin: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.85rem;
}
.event-tags .event-tag {
  display: flex;
  align-items: center;
  background-color: #2c7be5;
  margin: 0 1rem 0.5rem 0;
}
.event-tag svg {
  font-size: 1.2rem;
}
.event-bt-delete {
  cursor: pointer;
  text-align: right;
}
.event-bt-delete svg {
  font-size: 1rem;
  color: #e63757;
}
.event-bt-delete svg:hover {
  color: #183153;
}
.evento-name {
  height: 50px;
}

/* STEP 1 */
#create-event-1 .input-group-text inputs,
#create-event-1 .custom-select {
  font-size: 15px;
  height: 40px;
}
#banner-label,
#thumb-label {
  color: #95aac9;
}

#create-event-1 .btn {
  height: 40.5px;
}

/* STEP 2 */
#create-event-2 .create-ticket-type:last-child {
  margin-bottom: 3rem;
}
.tiposIngressosDiv {
  border-left: 2px solid #707070;
  margin-left: 6px;
}
.principalIngressos,
.secundarioIngressos {
  padding-left: 20px;
  list-style: none;
}

.principalIngressos > li,
.secundarioIngressos > li > div > label {
  color: #484848;
}

/* STEP 3 */
.setup-tickets:last-child {
  margin-bottom: 3rem;
}
.event-sector .event-lot-group:last-child {
  margin-bottom: 3rem;
}

.evento-traco-vertical {
  border-left: 1px solid #707070;
  height: 56px;
  margin-left: 78px;
}

.evento-traco-vertical-total {
  border-left: 1px solid #707070;
  height: 50px;
  margin-left: 50px;
}

.evento-icon-center {
  margin-left: 70px;
}

.evento-traco-horizontal {
  border-bottom: 1px solid #707070;
  max-width: 68px;
  width: 100%;
  margin-top: -14px;
  margin-left: 120px;
}
.evento-traco-horizontal-total {
  border-bottom: 1px solid #707070;
  width: 100%;
}
.evento-traco-horizontal-ini {
  border-bottom: 1px solid #707070;
  max-width: 75.703px;
  width: 100%;
  margin-left: auto;
}

.espacamento-top6 {
  margin-top: 6vh;
}
.espacamento-top7 {
  margin-top: 7vh;
}
.espacamento-top8 {
  margin-top: 8vh;
}
.espacamento-top10 {
  margin-top: 10vh;
}

.borda-arredondada {
  height: 30px;
  border: 1px solid #707070;
  border-radius: 20px;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  padding-top: 2px;
}
.borda-arredondada-sub {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0.75rem !important;
  border-radius: 6px;
  width: 100%;
  text-align: right;
  background-color: #e9ecef;
}

.card-datatable .card-header {
  font-size: 0.625rem !important;
  background-color: #f9fbfd !important;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: #95aac9;
  border-bottom-width: 1px;
  padding: 1rem !important;
}

.event-sector {
  background: #183153 !important;
  color: white;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
}
.event-lot {
  margin-top: 30px;
  align-items: center;
}
.event-pdv {
  background: grey;
}
.event-ticket-type {
  margin-top: 5px;
  align-items: center;
}
.event-ticket-type:last-child {
  margin-bottom: 3rem;
}

/* STEP 4 */
#congratulations {
  font-size: 92px;
  color: #2c7be5;
  font-weight: bold;
}
.no-margin-bottom {
  margin-bottom: 0px;
  color: #2c7be5;
}
.finalizacao {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
}

.finalizacao > div > p {
  font-size: 14px;
}

.step-title {
  flex-wrap: wrap;
  display: flex;
  justify-content: left;
  align-items: center;
}
.step-title span:last-child {
  font-size: 1rem;
  font-weight: bold;
}

.step-mobile {
  display: none;
  color: #2c7be5;
  font-size: 1rem;
  font-weight: bold;
  flex-direction: column;
  padding-top: 1.25rem;
}

/* MOBILE ADJUSTMENTS */
@media screen and (max-width: 768px) {
  #event-steps .step-step span:last-child {
    display: none;
  }
  .step-mobile {
    display: flex;
  }
}

#forms{
    text-align: center;
}

/* TABLE CONFIG */
#page-organizations .ktMXMg {
    display: block;
}

#page-organizations .borqHO,
#page-organizations .cGTTMH {
    min-width: 275px;
}

#page-organizations .event-img {
    width: 40px;
    height: 40px;
    margin-left: 0px;
}

.category-filter .select__control.css-1pe47xm-control {
    min-height: 48px;
}

@media screen and (max-width: 768px) {
    #page-organizations .hCbDYs {
        display: none;
    }

    .rdt_TableHeadRow #column-undefined {
        display: none;
    }

    #page-organizations .cGTTMH {
        display: none;
    }

    .category-filter {
        width: 100% !important;
    }
}
#subheader .nav-tabs {
  border-bottom-color: #e3ebf6;
}
#subheader .nav-link{
  background-color: transparent;
  border: 0;
  border-bottom: 3px solid transparent;
  margin-right:0.5rem;
  color: #95aac9;
  font-size: 0.8rem;
  cursor: pointer;
}
#subheader .nav-item:last-child .nav-link{
  margin-right:0;
}
#subheader .nav-link:hover{
  color: #6e84a3;
}
.card-step {
  margin-top: 10px;
  background-color: #e6f0f6;
  padding: 0.25rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}
.step-container {
  display: flex;
  padding: 0.5rem;
  flex-direction: row;
  width: 100%;
}
.step-content {
  width: 200px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: center;
}
.step-button-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}
.step-button-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;
}
.step-button-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.bar-left-side-container {
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.bar-left-side {
  height: 3px;
  background-color: #d8e2e7;
  width: 100%;
}
.bar-left-side-complete {
  height: 3px;
  background-color: #2075f4;
  width: 100%;
}
.step-button {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border: 3px solid #d8e2e7;
  border-radius: 15px;
}
.step-button-complete {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 4px;
  border: 3px solid #2075f4;
  border-radius: 15px;
}
.step-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}
.bar-right-side {
  width: 100%;
  background-color: #d8e2e7;
  height: 3px;
}
.bar-right-side-complete {
  width: 100%;
  background-color: #2075f4;
  height: 3px;
}
.step-button button {
  padding: 11px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.step-button:hover button {
  cursor: not-allowed;
}
.step-button-complete button {
  padding: 7px;
  background-color: #2075f4;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}
.step-info {
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  color: #4f6c7c;
}
.step-info-complete {
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  color: #2075f4;
}

.ocult {
  display: none;
}

.user-img-organization {
  width: 60px;
  height: 60px;
  border-radius: 200px;
  background-size: cover;
  flex: none;
  border: 3px solid;
}

.tip-trust-level {
  width: 240px;
  background-color: #12263f;
  color: #95aac9;
  text-align: left;
  border-radius: 6px;
  padding: 0.8rem 0.95rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -180px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.75rem;
  font-weight: normal;
  white-space: normal;
}

.cep-info {
  color: #2C7BE5;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-top: 1.6rem;
}

@media (max-width: 767px) {
  .cep-info {
    margin-top: 0;
  }
}
.product-span {
  margin-top: -50px;
  font-size: 14px;
  color: rgb(90, 90, 90);
}

.product-p {
  margin-bottom: 0px;
  color: rgb(0, 0, 0)
}

.img-product {
  border: 2px solid;
  width: 55px;
  height: 55px;
  margin: 5px;
  border-radius: 80px;
  background-size: cover;
  flex: none;
}


/* // TESTE */
.size-button{
  font-size: 15px;
}

.size-buttons{
  font-size: 16px;
}

#list-establishments .card-list .dotsMobile {
  position: absolute !important;
  top: 20px !important;
}

#list-establishments .card-establishments {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 0.5rem 0 !important;
}

.card-establishments .card-establishments-infos {
  display: flex;
  align-items: center;
}

.card-establishments {
  position: relative;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.show-dropdown {
  display: block !important;
}

#list-establishments .dotsMobile {
  padding: 10px;
  top: 0;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  right: 1rem;
  z-index: 9000;
  color: #95aac9;
  background: transparent;
  border: none;
  cursor: pointer;
}

.categorie-responsive {
  position: relative;
  width: 100%;
  border-radius: inherit;
  overflow: auto hidden;
  min-height: 0px;
}

.categorie-options {
  position: relative;
  width: 100%;
  display: table;
}

.card-categories {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
  color: rgb(79, 108, 124);
}
.title-categorie {
  display: flex;
  align-items: stretch;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-height: 56px;
  border-bottom: 1px solid rgb(233, 242, 242);
}

.title-categorie, .subRow {
  border-radius: 8px;
}

.categorie-temas {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  color: rgb(79, 108, 124);
  padding-left: 16px;
  padding-right: 16px;
  flex: 1 0;
  max-width: 100%;
  min-width: 100px;
}


.title-information {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  flex: 1 0;
  max-width: 100%;
  min-width: 100px;
  font-weight: 400;
}

.title-information div:first-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.categorie-bad {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  flex: 1 0;
  max-width: 100%;
  min-width: 100px;
  font-weight: 400;
}

div .rotated svg{
  transform: rotate(180deg); 
}

div .arrow-active{
  opacity: 1;
}

.img-product {
  border: 2px solid;
  width: 55px;
  height: 55px;
  margin: 5px;
  border-radius: 80px;
  background-size: cover;
  flex: none;
}


.card-category{
    height: 50px;
}
.integrations-card {
  min-height: 16rem;
}
.icon-card {
  min-width: 48px;
  min-height: 48px;
  border: 1px solid #f5f5f7;
  border-radius: 0.5rem;
}
.integrations-card:hover {
  border: 1px solid #0050c3;
}

.integrations-card.active {
  border: 1px solid #00c67f;
}

.integrations-card.expired {
  border: 1px solid #F3AF00;
}



.integration-switch-label {
  font-family: "Lato";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.313rem;
  letter-spacing: 0px;
  text-align: left;
}

.description-text {
  color: #525059;
  font-size: 14px;
}

.label-text {
  color: #171719;
  font-size: 16px;
  font-weight: bold;
}

.new-copy-button {
    background-color: transparent;
    border: none;
    width: 100%;
}

.radiobutton-input {
  display: none;
}
.custom-radiobutton {
  padding: 0.5rem;
  border: 1px solid #d3d2d9;
  border-radius: 0.5rem;
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}
.custom-radiobutton-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid #d3d2d9;
}
.custom-radiobutton-circle-active {
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #0050c3;
}

.custom-field {
  position: absolute;
  width: 90%;
  display: inline-flex;
  z-index: 2;
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.5rem;
  padding: 1rem 0.5rem 0.8rem 0.5rem;
}

.form-content {
  min-height: 10rem;
  max-height: 30rem;
}

.remove-button {
  background-color: transparent;
  border: none;
}

.hiddenX {
  overflow-y: scroll;
  overflow-x: hidden !important;
}

.custom-field-save-button {
  background: none;
  border: none;
  color: #0050c3;
  font-weight: bolder;
}
.buttons-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
}

.back-button {
  background: transparent;
}

.button-modal-back,
.button-modal-next {
  width: 7.625rem;
}

.button-modal-submit {
  width: 10.5rem;
}

.modal-content-custom-input {
  width: 100%;
  color: #3a3940;
  font-family: "Lato";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;
}

.modal-content-custom-input:focus-visible {
  outline: none;
}

.modal-content-custom-input::-webkit-input-placeholder {
  font-family: "Lato";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0px;
  text-align: left;
  color: #767380 !important;
}

@media screen and (max-width: 768px) {
  .removable-row {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: auto auto;
  }

  .removable-row-item {
    grid-column: 1;
  }

  .buttons-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .remove-button {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .form-content {
    max-height: 100%;
  }

  .button-modal-back,
  .button-modal-next,
  .button-modal-submit {
    width: 100%;
  }
}

.integrations-button {
  display: flex;
  align-items: center;
}

.update-token-button{
  background-color: transparent;
  border: none;
  text-decoration: underline;
  font-weight: 700;
}

.page-body{
  padding-left: 24px;
  padding-right: 24px;
}

.expired{
  color: #730A46
}

.container-link {
  border: 1px solid #ced4da;
  padding: 8px 12px;
  border-radius: 0.375rem;
}

@media (max-width: 768px) {
  .integrations-button {
    width: 100%;
  }
}

.new-alert {
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
}

.new-alert-warning > p {
    color: #171719;
}

.new-alert-warning {
    background-color: #FFF7E4;
    border: 1px solid #F3AF00;
}
@font-face {
    font-family: 'Cerebri Sans';
    src: url(/static/media/cerebrisans-regular.e63b280f.eot);
    src: url(/static/media/cerebrisans-regular.e63b280f.eot?#iefix) format("embedded-opentype"),
        url(/static/media/cerebrisans-regular.548f0235.woff) format("woff"),
        url(/static/media/cerebrisans-regular.91bff5f0.ttf) format("truetype");
    font-weight: 400;
    font-style: normal
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url(/static/media/cerebrisans-medium.c01e4a7d.eot);
    src: url(/static/media/cerebrisans-medium.c01e4a7d.eot?#iefix) format("embedded-opentype"),
        url(/static/media/cerebrisans-medium.abe53ace.woff) format("woff"),
        url(/static/media/cerebrisans-medium.66f3c3ae.ttf) format("truetype");
    font-weight: 500;
    font-style: normal
}

@font-face {
    font-family: 'Cerebri Sans';
    src: url(/static/media/cerebrisans-semibold.2a1663dd.eot);
    src: url(/static/media/cerebrisans-semibold.2a1663dd.eot?#iefix) format("embedded-opentype"),
        url(/static/media/cerebrisans-semibold.16e9c1c6.woff) format("woff"),
        url(/static/media/cerebrisans-semibold.25aa37ee.ttf) format("truetype");
    font-weight: 600;
    font-style: normal
}

.dashboard-style {
    font-family: "Cerebri Sans", sans-serif !important;
}

.dashboard-style h6.text-uppercase {
    letter-spacing: .08em;
    font-size: .625rem;
}

.dashboard-style .h2,
.dashboard-style h2 {
    font-size: 1.25rem;
}

.dashboard-style h1,
.dashboard-style h2,
.dashboard-style h3,
.dashboard-style h4,
.dashboard-style h5,
.dashboard-style h6 {
    letter-spacing: -.02em;
}

.dashboard-style .badge {
    padding: .33em .5em;
    margin-left: 0.5rem;
}

.dashboard-style .svg-inline--fa {
    font-size: 1rem;
}

#events-dashboard .event-img {
    width: 40px !important;
    height: 40px !important;
    float: left;
    margin-right: 1rem;
}

#events-dashboard tr {
    display: flex;
    justify-content: space-between;
}

#events-dashboard tbody tr {
    border-top: 1px solid #edf2f9;
}

#events-dashboard tbody th {
    border-top: 1px solid #edf2f9;
}

#events-dashboard td {
    display: flex;
    align-items: center;
    border-top: 0px;
}

/* .btn-super {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */

#financial-dashboard td {
    padding: 0.75rem 1.5rem;
}

.financial-result td {
    font-weight: bold !important;
    background-color: #e9f2f2;
}

.financial-detail td {
    color: #6e84a3 !important;
    background-color: #f9fbfd;
}

.financial-detail td:first-child {
    padding-left: 2.5rem !important;
}

.financial-minus td {
    color: #e63757 !important;
    background-color: #f9fbfd;
}

#selectEvent {
    cursor: pointer;
}

.th-header-start {
    display: flex;
    justify-content: flex-start;
}
.ajuste-card {
  padding: 0;
  border-radius: 8px;
}
.body-card-color-black {
  color: #000;
  font-weight: bold;
}
.margin-top {
  margin-top: 15px !important;
}
#page-dashboard-ticket .card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 45px;
}
.dashboard-card-sales h3 {
  font-family: "Rubik";
  font-size: 1rem;
  color: #600ea3;
}
.dashboard-card-sales .sales-header {
  color: #183153;
  font-size: 0.825rem;
}
.dashboard-card-sales .sales-infos {
  color: #95aac9;
  font-size: 0.825rem;
}
.dashboard-card-sales div:last-child hr {
  display: none;
}
.card.shadow {
  min-height: 406px;
}
#sales-by-ticket,
#sales-origin {
  min-height: 520px;
}
.card-report {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0;
  color: #e9f2f2;
  border-radius: 8px;
  opacity: 1;
  padding: 10px 23px 10px 23px;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  max-height: 150px;
  min-height: 150px;
}
.card-report-title {
  color: #ffffff;
  margin: 0;
  font-weight: bold;
  font-size: 0.825rem;
}
.card-report-value {
  text-align: start;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
  margin: 0;
}
.card-report-subtotal,
.card-report-subtitle {
  color: #ffffff;
  margin: 0;
  font-weight: 400;
  font-size: 12px;
}

.no-sales-title {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;
  color:#3A3940 ;
}

.no-sales-subtitle {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #525059;
}

.new-sales-card-title {
  font-family: Lato;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
  text-align: left;
  color: #525059;
}

.new-sales-title-primary {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #0050C3;
  margin-bottom: 4px;
}

.new-sales-value-primary {
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #0050C3;
  margin-bottom: 4px;
}

.new-sales-title-ticket, .new-sales-title-taxes {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #525059;
}

.new-sales-value-taxes {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #730A46;
}

.new-sales-value-ticket {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #00422A;
}

.new-detailed-sales, .new-sales-header-card {
  position: relative;
  padding-bottom: 10px; 
  margin-bottom: 10px;
}

.new-detailed-sales::after, .new-sales-header-card::after {
  content: "";
  display: block;
  width: calc(100% - 49px);
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
}

.new-detailed-sales:last-child::after, .new-sales-header-card:last-child::after {
  display: none;
}

.new-sector-title, .new-sector-quantity-information, .new-sector-value {
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #171719;
}

.new-lot-title, .new-lot-quantity, .new-lot-value {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #525059;
}

.new-type-name, .new-type-quantity, .new-type-value {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 6px;
  color: #525059;
}


@media screen and (max-width: 768px) {
  .card-report-value {
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 25px;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
    margin: 0;
  }

  .card-report {
    font-family: "Roboto", sans-serif;
    letter-spacing: 0;
    color: #e9f2f2;
    border-radius: 8px;
    opacity: 1;
    padding: 10px;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #542cf5;
    max-height: 120px;
    min-height: 120px;
  }
  .card-report-title {
    color: #ffffff;
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 0.825rem;
  }

  .card-report-subtotal,
  .card-report-subtitle {
    text-align: center;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
    font-size: 12px;
  }
}

.expanderIcon{
    margin-left: 20px;
    background: transparent;
    outline: none !important;
    color: blue;
}

.subRow {
  border: 2px solid #3f25a8;
}
.margin0 {
  margin: 0 !important;
}
.qCZjB:hover:not(:disabled) {
  background: none;
}
.iuCFTz {
  border-radius: 0 0 8px 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.card .gAuPhq {
  border-radius: 0px !important;
}
@media screen and (min-width: 924px) {
  .gAuPhq {
    overflow-x: hidden !important;
  }
}
.comprador-detail p {
  color: #4f6c7c;
  font-size: 1em;
}
.participantes-header {
  background-color: #f9fbfd !important;
  padding: 10px 0;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 0.8rem;
}
.participante-detail {
  margin-top: 5px;
}
.payment-icon {
  width: 25px !important;
  margin-right: 0.5rem;
  filter: invert(60%) sepia(26%) saturate(208%) hue-rotate(158deg)
    brightness(101%) contrast(94%);
}
button.btn-red-border {
  background-color: transparent !important;
  border: 2px solid #e63757 !important;
  color: #183153;
}
.btn-red-border svg {
  color: #e63757;
}
button.btn-red-border:hover {
  color: #e63757;
}
.sale-btn {
  display: flex;
  justify-content: space-around;
}
#table-totalsales thead th {
  cursor: pointer;
}
#table-totalsales thead th {
  opacity: 0.5;
}
#table-totalsales thead th svg {
  margin-left: 0.25rem;
}
#table-totalsales thead th.rotated svg {
  transform: rotate(180deg);
}
#table-totalsales thead th.arrow-active {
  opacity: 1;
}
#table-totalsales tr {
  cursor: pointer;
}
#table-totalsales .badge {
  min-width: 75px;
}
#table-totalsales .payment-type {
  color: #95aac9;
}
#table-totalsales .payment-type svg {
  color: #6e84a3;
}

.input-form-container {
  width: 100%;
}

@media (max-width: 768px) {
  .sale-btn {
    margin-left: 15px;
    width: 30%;
  }

  ReactModal__Content ReactModal__Content--after-open {
    transform: translate(-50%, -60%) !important;
  }
}

.user-name {
  font-size: medium;
  font-weight: bold;
}

.user-name-p {
  color: #999;
  font-size: 25px;
  font-weight: bold;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.user-data-p {
  font-size: 14px !important;
  color: #4f6c7c !important;
  font-weight: normal !important;
}
.user-img {
  width: 80px;
  height: 80px;
  float: left;
  border-radius: 200px;
  background-size: cover;
  flex: none;
  border: 3px solid;
}
.profile-picture {
  display: flex;
  justify-content: space-between;
}

#user-docs .card-img-upload .input-group-text svg {
  margin-right: 0;
}

#subheader .nav-link.ativo {
  border-bottom-color: #2c7be5;
  color: #12263f;
}

@media screen and (max-width: 768px) {
  .profile-picture {
    padding: 0px 110px;
  }
}

.button-new {
  border-bottom: solid 2px #ccddeb;
  padding: 20px;
}

.table-users {
  padding: 20px;
}

.border-red {
  padding-top: 5px;
  text-align: center;
}

.border-green {
  padding-top: 5px;
  text-align: center;
}

.border-blue {
  padding-top: 5px;
  text-align: center;
  border: 2px solid #212991;
}

.cor-azul {
  color: #212991 !important;
  cursor: pointer;
  padding: 0px 10px;
}

.cor-azul:hover {
  color: #4eb7ba !important;
}

.table td {
  vertical-align: middle;
}

.cor-verde {
  color: #32a852;
  cursor: pointer;
  padding: 0px 10px;
}

.cor-verde:hover {
  color: #4aff7b !important;
  padding: 0px 10px;
}

.expanded-title {
  border-bottom: solid 1px #212991;
  color: #212991;
}

.expanded-content {
  margin-left: 2px;
}

.badge.badge-success {
  background-color: #00d97e;
  color: white;
}
#venda-detalhe form {
    height: auto;
}

.custom-confirm-button-outline {
    border: 1px solid #0050C3 !important;
    font-weight: 700 !important;
    color: #0050C3 !important;
}

.custom-confirm-button-outline:active {
    background-color: rgba(14, 54, 163, 0.3) !important;
}

.info-user {
    font-size: 14px;
    font-weight: 400;
    color: #6E84A3;
}
.form-register-user{
    padding: 5px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.form-register-user label{
    padding-bottom: 0px;
}

.submit-button{
    padding-top: 20px;
}

.register-user h1{
    font-size: large;
    padding-left: 5px;
    font-weight: bold;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.button-dados-pessoais{
    align-items: center;
}

.dados-pessoais {
    overflow:hidden;
    max-height: 500px;
    transition: max-height 0.9s ease-in-out;
    width: 100%;
  }

  .invalid-cep{
      border-radius: 5px;
      text-align: center;
      padding: 5px;
      margin-top: 3px;
      overflow: hidden;
      max-height: 200px;
      transition: max-height 0.4s ease-in-out;
  }

  .invalid-cep.hide{
      display: none;
  }
  .invalid-cep-show{
    border-radius: 5px;
    /* text-align: center; */
    /* padding: 5px; */
    margin-top: 3px;
    overflow: hidden;
    max-height: 200px;
    transition: max-height 0.4s ease-in-out;
    /* border: 2px solid #e63757; */
}

.invalid-cep-p{
    font-size: 12px;
    color: #e63757;
}

.radio-selecter{
    padding: 20px;
}

.radio-selecter label{
    padding-left: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.radio-selecter input{
position: absolute;
cursor: pointer;
width: 20px;
padding-bottom: 3px;
margin-bottom: 10px;
}
 
.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 83px;
  background-color: #f5f8f8;
  padding: 10px;
  z-index: 9999;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    max-height: 62.5rem;
  }

  100% {
    opacity: 0;
    max-height: 0;
  }
}

.sub-items-group-closed,
.sub-items-group {
  display: flex;
  flex-direction: column;
  font-size: 0.88rem;
  font-weight: 500;
  background: rgba(0, 0, 0, 0.1);
  transform-origin: top;
  overflow: hidden;
}

.sub-items-group-closed {
  animation: fade-out 250ms ease-in-out forwards;
  height: 0;
}

.sub-items-group {
  animation: fade 250ms ease-in-out forwards;
  text-indent: 17%;
  max-height: 62.5rem;
  opacity: 1;
}

.button-zig {
  display: flex;
  padding: 0.5rem 1rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border-color: transparent;
  background: #0050c3;
  font-weight: 700;
  width: 11.875rem;
  justify-content: space-evenly;
  color: #fff;
}

#sidebar {
  /* background: url("/icons/zig.png") bottom right no-repeat; */
}

#sidebar .nav-active {
  color: #ffffff;
}

#sidebar .dropdown-container .nav-active {
  color: #00a7e5;
}

.button-aeb {
  border: 0.063rem solid #ffffff;
  border-radius: 0.5rem;
  opacity: 1;
  width: 12.5rem;
  height: 2.5rem;
  background: transparent;
  align-items: center;
  display: flex;
  justify-content: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  margin: 1.25rem 0.625rem;
}

.button-aeb p {
  font-weight: 700;
  color: #fff;
  margin-top: 0.6rem;
}

.c3.sidebar-toggle .button-aeb p {
  display: none;
}

.c3.sidebar-toggle .button-aeb {
  padding: 0.6rem;
  width: -moz-fit-content;
  width: fit-content;
}

.c3.sidebar-toggle .nav-active {
  margin-left: -6.9rem;
  justify-content: center;
  display: flex;
}

.c3.sidebar-toggle .nav-active img {
  margin-right: -4rem;
}

.redirect-button-zig {
  display: flex;
  justify-content: center;
  margin-top: 1.05rem;
  z-index: 2;
  margin-bottom: 35%;
}

.version-container-producer {
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 0.188rem;
  margin-left: 0.5rem;
  margin-bottom: 1.25rem;
  justify-content: center;
  border-radius: 0.25rem;
  width: 3.125rem;
  background: #0050c3;
  z-index: 2;
}

.version-container-admin {
  display: flex;
  position: relative;
  margin-top: 6rem;
  padding: 0.188rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  justify-content: center;
  border-radius: 0.25rem;
  width: 3.125rem;
  background: #0050c3;
  z-index: 2;
}

#sidebar {
  position: fixed;
  color: #fff;
  z-index: 20000;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  max-width: var(--sidebar-width);
  height: 100%;
  overflow-x: hidden;
}

#sidebar::-webkit-scrollbar {
  width: 0px;
}

.c3.sidebar-toggle .icons {
  position: static;
  width: 1.5rem;
  height: 1.5rem;
  transition: all 0.3s;
  z-index: 999;
}

.c3.sidebar-toggle #sidebar-list {
  display: flex;
  flex-direction: column;
  margin-left: 0.35rem;
  list-style: none;
}

.c3.sidebar-toggle #sidebar-list .change-language {
	margin-left: -0.35rem;
}
.c3.sidebar-toggle #sidebar-list .change-language .icons{
	margin-left: 0.35rem;
}

.c3.sidebar-toggle {
  width: -10rem;
}

.c3.sidebar-toggle .arrow-icon,
.c3.sidebar-toggle .item-container span,
.c3.sidebar-toggle .version-container-admin,
.c3.sidebar-toggle .version-container-producer,
.c3.sidebar-toggle #sidebar-logo,
.c3.sidebar-toggle .redirect-button-zig,
.c3.sidebar-toggle .external-link {
  display: none;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.arrow-icon-open {
  right: 0.5rem;
  transform: rotateZ(90deg);
  transition: 0.3s;
}

.arrow-icon {
  position: absolute;
  right: 0.5rem;
  top: 30%;
  transition: 0.3s;
}

.external-link {
  position: absolute;
  right: 1rem;
  top: 23%;
}

.sub-item {
  width: 100%;
  padding: 0.6rem;
  white-space: nowrap;
}

.sub-item:hover {
  background: rgba(0, 0, 0, 0.2);
}

.sidebar-divider {
  border-top: 0.031rem opacity black;
  margin: 0.25rem;
}

#sidebar-logo {
  width: 65%;
}

.toggle-icon {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  grid-gap: 0.4rem;
  gap: 0.4rem;
  height: 2.5rem;
}

.bar {
  width: 1.5rem;
  height: 0.188rem;
  background-color: #fff;
  transition: transform 0.3s;
}

.menu-shape .bar {
  transform: scaleX(1);
}

.x-shape .bar:first-child {
  transform: rotate(45deg) translate(0.35rem, 0.2rem);
  margin-top: 0rem;
  border-bottom: 0.188rem solid white;
}

.x-shape .bar:nth-child(2) {
  display: none;
  border-bottom: 0.188rem solid white;
}

.x-shape .bar:last-child {
  transform: rotate(-45deg) translate(0.35rem, -0.22rem);
  margin-top: 0.188rem;
  border-top: 0.188rem solid white;
}

#sidebar span {
  padding-left: 0.5rem;
}

.sidebar-header {
  padding: 1.25rem 0.625rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
}
.change-language{
	border-top: #B7B6BF 0.063rem solid;
	display: flex;
	cursor: pointer;
}


#sidebar #sidebar-list .item-container {
  display: flex;
  position: relative;
  align-items: center;
  width: 13.75rem;
  padding: 1.6rem 1.6rem 1.6rem 0.7rem;
  cursor: pointer;
  height: 3rem;
}

#sidebar-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  scrollbar-width: thin;
}

.icons {
  width: 1.5rem;
  height: 1.5rem;
  align-self: center;
}

.item-container span {
  font-size: 0.9rem;
  color: #ffff;
  font-weight: 500;
}

@media screen and (min-width: 760px) and (min-height: 1366px) {
  /* .version-container-admin, .version-container-producer {
    margin-top: auto;
    position: absolute;
    bottom: 0.5rem;
  } */
}

@media screen and (min-width: 150px) and (max-width: 991px) {
  .c3.sidebar-toggle {
    width: 0rem;
  }
}

.navbar {
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
}

#nave .navbar {
  padding: 0;
}

.account-header-div {
  grid-gap: 8px;
  gap: 8px;
}

#nave #main-header {
  padding-top: 1rem;
  padding-bottom: 5px;
  justify-content: center;
  display: flex;
  align-items: center;
}

#nave #main-header #header-breadcrumb {
  padding-left: 0 !important;
}

#nave #main-header #header-breadcrumb {
  display: flex;
}

#nave #menu-bt {
  padding: 0;
  font-size: 1.5rem;
}

#nave .dropdown-menu {
  color: #6e84a3;
  border: 1px solid rgba(18, 38, 63, 0.1);
  border-radius: 0.375rem;
}

.background-breadcrumb {
  background-color: #fff;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  font-size: 15px;
  color: #57a7e4;
}

.breadcrumb a:hover {
  color: #600ea3;
  transition: color 0.3s;
}

.toogle-side-a {
  color: #600ea3 !important;
  padding: 8px 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  margin-left: 0 !important;
}

.toogle-side-a:hover {
  background-color: #600ea3;
  color: #fff !important;
  border-radius: 5px;
}

#nave #main-header #headerbarSupportedContent {
  padding-left: 0 !important;
}

#headerbar-usercontent {
  display: flex;
  align-items: center;
  flex-flow: row;
}

#nave #main-header #menu-close-bt {
  padding-right: 0 !important;
}

@media screen and (max-width: 991px) {
  #nave #main-header #header-userinfo {
    display: none;
  }
}

.img-user {
  position: relative;
  float: left;
  width: 50px;
  height: 50px;
  background-size: cover;
  border-radius: 50px;
  border: 2px solid #fff;
  background-color: #183153;
}

#header-userinfo {
  color: #4f6c7c;
  font-size: 13px;
  margin: 0 0.5rem 0 2rem;
  text-align: right;
}

#header-userinfo span {
  display: block;
  font-size: 0.75rem;
}

#header-username {
  font-weight: bold;
  color: #4f6c7c;
}

#usermenu {
  position: relative;
}

#usermenu #header-userimg {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#dropdown-usermenu {
  width: 180px;
  left: -110px;
  margin-top: 0;
  position: absolute !important;
}

#dropdown-usermenu .dropdown-item {
  padding: 0.35rem 0.75rem;
  font-size: 0.85rem;
  color: #6e84a3;
  cursor: pointer;
}

#dropdown-usermenu .dropdown-item:hover {
  background: #edf2f9;
}

#dropdown-usermenu .dropdown-item svg {
  margin-right: 0.5rem;
  font-size: 0.88rem;
  color: #6e84a3;
}

@media screen and (max-width: 768px) {
  #nave #main-header {
    padding: 0;
  }
}

#nave .alert {
  margin: -40px 5px 46px 5px;
}

.dotsHeader {
  color: #6e84a3;
  cursor: pointer;
}
#main-header{
  justify-content: flex-start;
}
#main-header .breadcrumb-separator {
  color: #6E84A38A !important;
  margin: auto 4px;
  -webkit-user-select: none;
          user-select: none;
  font-size: 0.625rem;
}

.breadcrumb-item {
  color: #D3D2D9;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  text-transform: uppercase;
}

.breadcrumb-last-item {
  color: #767380;
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; 
  text-transform: uppercase;
}

.breadcrumb-item a:hover{
  color: #767380 !important;
}

#main-header ol {
  margin-bottom: 0px;
}

.breadcrumb-collapser {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  /* #main-header .icon-home{
    display: none;
  } */
  #main-header ol {
    margin-left: 0;
  }

  .breadcrumb-mobile{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 125px;
  }

  

}
#new-account-details {
    padding: 4px 8px;
    border: 1px solid #D3D2D9 ;
    border-radius: 8px;
    background-color: transparent;
    grid-gap: 8px;
    gap: 8px;
}

#new-account-details:hover {
    background-color: #F5F5F7;
}

.account-details-dropdown-name{
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #171719;
}

.account-details-dropdown-email{
    font-family: Lato;
    font-size: 12px;
    max-width: 180px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #525059;
}

.account-details-show-qr-code {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #C8E6FA80;
    border: none;
}

.account-details-dropdown-header {
    grid-gap: 16px;
    gap: 16px;
    padding: 16px;
    border-bottom: 1px solid #D3D2D9;
}


.account-details-option {
    padding: 8px 16px;
    background-color: transparent;
    grid-gap: 35px;
    gap: 35px;
    border: none;
}

.account-details-logout {
    border-top: 1px solid #D3D2D9;
}

.account-details-logout-button{
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 8px 16px;
    grid-gap: 35px;
    gap: 35px;
}

.account-details-logout-info{
    grid-gap: 8px;
    gap: 8px;
}

.account-details-option:hover, .account-details-logout-button:hover {
    background-color: #EAE9ED;
}

.account-details-logout-button:hover {
    border-radius: 0px 8px 0px 8px;
}

.avatar-name-details {
    grid-gap: 8px;
    gap: 8px;
}

.hello-text-account {
    font-family: Lato;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #171719;
}

#account-details-dropdown {
    min-width: 239px;
    min-height:265px;
    max-width: 282px;
    border-radius: 8px;
    border: 1px solid #D3D2D9;
    background-color: #FFFFFF;
    position: absolute;
    top: 44px;
    right: 1px;
    z-index: 10;
}

@media (max-width: 768px) {
    #account-details-dropdown {
        top: 39px;
    }
}
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    color: #fff;
    padding: 6px;
    font-size: 12px;
    border-radius: 8px;
    background: #2F2E33;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s;
}

.tooltip-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

.tooltip-top {
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-bottom {
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-right {
    left: 120%;
    top: 50%;
    transform: translateY(-50%);
}

.tooltip-left {
    right: 120%;
    top: 50%;
    transform: translateY(-50%);
}
#bottom-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20000;
  background-color: #183153;
  display: none;
}
#bottom-bar .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#bottom-bar .container span {
  font-size: 0.6rem;
  font-weight: 300;
  margin-top: 0.2rem;
}
#bottom-bar .bottom-bar-icon {
  width: 40px;
  height: 40px;
  margin: 0.65rem;
  font-size: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
#bottom-bar .bottom-bar-mainicon {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-top: -20px;
  font-size: 1.5rem;
  color: white;
  background-color: #00a7e1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.buttonWhatsapp {
  background-color: transparent;
  color: #fff;
  border: 1px solid transparent;
}

@media screen and (max-width: 768px) {
  #bottom-bar {
    display: block;
  }
}

#bottom-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20000;
  background-color: #183153;
  display: none;
}
#bottom-bar .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#bottom-bar .container span {
  font-size: 0.6rem;
  font-weight: 300;
  margin-top: 0.2rem;
}
#bottom-bar .bottom-bar-icon {
  width: 40px;
  height: 40px;
  margin: 0.65rem;
  font-size: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
#bottom-bar .bottom-bar-mainicon {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-top: -20px;
  font-size: 1.5rem;
  color: white;
  background-color: #00a7e1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.buttonWhatsapp {
  background-color: transparent;
  color: #fff;
  border: 1px solid transparent;
}

@media screen and (max-width: 768px) {
  #bottom-bar {
    display: block;
  }
}

/* SETUP */
a,
a:hover,
a:focus {
  transition: all 0.2s !important;
}

.content {
  width: 100%;
  padding: 0px 80px;
  padding-top: 0px;
  transition: all 0.3s;
  padding-bottom: 30px;
}

.page-content {
  margin-bottom: 50px;
}

.display-nocontent {
  text-align: center;
  width: 100%;
  border-radius: 8px;
  background: white;
  padding: 25px;
}

.table-condensed {
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f9fbfd !important;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  text-align: left;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #95aac9;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.wrapper-light {
  display: flex;
  width: 100%;
  align-items: stretch;
  background: transparent;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.container-index {
  padding-top: 0px;
  width: 100%;
  margin-left: 0;
  min-height: 100vh;
  transition: all 0.3s;
  background: #f5f8f8;
}

.container-index.logged {
  padding-top: 0px;
  width: 100%;
  margin-left: var(--sidebar-width);
  min-height: 100vh;
  transition: all 0.3s;
  background: #f5f8f8;
}

@media screen and (min-width: 150px) and (max-width: 991px) {
  .container-index.logged {
    margin-left: 0;
    transition: all 0.3s;
  }
}

.swal-wide {
  width: 100vw !important;
}

.swal2-shown {
  overflow: unset !important;
  padding-right: 0px !important;
}

ol {
  list-style: none;
  display: flex;
  align-items: center;
}

.btn svg {
  margin-right: 0.5rem;
}

.btn-info {
  color: #fff;
}

.btn-info:hover {
  color: #fff;
}

.btn-info:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #edf2f9;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  border-color: #edf2f9;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.card-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #edf2f9;
}

.card-header-title {
  margin-bottom: 0;
}

h4.card-header-title {
  color: #12263f;
  font-size: 0.825rem;
  font-weight: bold;
}

.card-content {
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
  background: #fff;
  color: #4f6c7c;
}

/* FORMS */
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

input::placeholder {
  color: #12263f !important;
}

select option {
  color: #12263f !important;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2ddec;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b1c5d8 !important;
  opacity: 1;
  /* Firefox */
}

.card-search .input-custom::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #95aac9 !important;
  opacity: 1;
  /* Firefox */
}

.card-search .input-custom:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #95aac9 !important;
}

.card-search .input-custom::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #95aac9 !important;
}

.card-search .input-custom::-webkit-input-placeholder {
  color: #95aac9 !important;
}

.btn-height {
  height: 48px;
}

h1 {
  font-family: "Rubik", sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #4f6c7c;
  text-align: left;
}

.subtitle {
  color: #4f6c7c;
  margin-top: 5px;
  margin-left: 5px;
  font-family: "Rubik", sans-serif;
}

.form-control {
  border: 1px solid #ced4da;
}

.form-control-backColor {
  background-color: transparent !important;
  color: #4f6c7c !important;
}

.form-control:hover {
  border-color: #00a5e3;
}

.form-control:focus {
  border-color: #00a5e3 !important;
  box-shadow: inset 0 1px 1px rgba(241, 38, 38, 0.075),
    0 0 8px rgba(0, 162, 255, 0.473) !important;
}

.card-buttons {
  display: flex;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: attr(data-content);
  color: #fff;
  z-index: 0;
}

.custom-file-label::after {
  color: #4f6c7c;
  background-color: #4f6c7c;
  z-index: 0;
}

.custom-file-label {
  z-index: 0;
}

.custom-file-input {
  z-index: 0;
}

/* TABLES */
.table td,
.table th {
  line-height: normal;
  font-size: 12px;
  font-weight: 500;
  color: #6e84a3;
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #edf2f9;
}

.table thead {
  border-bottom: transparent;
}

.table th {
  background-color: transparent;
}

.table-sm thead th {
  font-size: 0.625rem;
}

.table-sm td,
.table-sm th {
  padding: 0.75rem !important;
}

.table-round-corner {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  overflow: hidden;
  border-collapse: separate;
  border: solid #ccc 1px;
  border-radius: 25px;
}

.table tbody {
  border-radius: 15px;
}

.footer-table th {
  line-height: normal;
  font-size: 15px;
  font-weight: bold;
  color: #edf2f9;
  padding-left: 3rem;
  vertical-align: top;
  padding-bottom: 0.3rem;
}

.footer-table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.76);
}

.footer-table td {
  line-height: normal;
  text-align: right;
  font-size: 12px;
  color: #edf2f9;
  padding-left: 3rem;
  vertical-align: bottom;
  padding-top: 0.3rem;
}

.datatable-custom {
  background-color: white;
}

.datatable-custom.table .thead-light tr,
.datatable-custom.table .thead-light tr:hover {
  background-color: #dee2e6 !important;
}

.datatable-custom.table .thead-light th {
  background-color: transparent !important;
}

.datatable-custom tr:hover {
  background-color: #e2e6ed !important;
}

td {
  vertical-align: middle !important;
  color: #6e84a3;
}

.datatable-custom td.td-total {
  background-color: #95aac9;
  color: white;
  font-weight: bold;
}

.tb-small td {
  padding: 0.5rem !important;
}

.datatable-custom .btn-outline-secondary {
  color: #6e84a3;
  border: 2px solid #6e84a3 !important;
}

.datatable-footer {
  color: #95aac9;
  border-top: 1px solid #edf2f9;
  padding: 0.75rem 0;
}

.datatable-footer span,
.datatable-footer select {
  font-size: 0.75rem;
}

.datatable-footer select {
  color: #95aac9;
  border-color: #95aac9;
  cursor: pointer;
}

.datatable-footer select:hover {
  color: #183153;
}

.datatable-buttons button {
  background-color: transparent;
  border: none;
  color: #6e84a3;
  padding: 0 0.35rem;
}

.datatable-buttons button:hover {
  color: #0050c3;
}

.card-table.table {
  margin-bottom: 0px;
}

.card-table.table thead {
  border-top: 0px solid #edf2f9;
  background-color: #f9fbfd !important;
  border-bottom: 0px;
}

.card-table tbody td:first-child,
.card-table thead th:first-child {
  padding-left: 1.5rem !important;
}

.card-table tbody td:last-child,
.card-table thead th:last-child {
  padding-right: 1.5rem !important;
}

.card-table .thead-light th {
  padding: 1rem !important;
  background-color: transparent;
  font-size: 0.625rem;
  letter-spacing: 0.15em;
  color: #95aac9 !important;
}

.card-table tr:hover {
  background: #f9fbfd;
}

.card-table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 1370px) {
  .footer-table th {
    padding-left: 2rem;
  }

  .footer-table td {
    padding-left: 2rem;
  }

  .mobile-max {
    max-width: 1100px !important;
    overflow: hidden;
    overflow-x: scroll;
  }

  .tabela {
    overflow-x: scroll !important;
  }

  .sector {
    overflow-x: scroll !important;
  }

  .hiddenX {
    overflow-y: scroll;
    overflow-x: hidden !important;
  }
}

/* SWEETALERT CUSTOM */
.swal2-title {
  color: #12263f;
  font-size: 1.75rem;
}

.swal2-content {
  color: #506d7c;
  font-size: 1rem;
}

.swal2-actions button {
  font-size: 1rem !important;
  font-weight: normal;
}

.swal2-container.swal2-center {
  z-index: 10000;
  align-items: center;
}

.swal2-icon.swal2-warning {
  border-color: #f6c343;
  color: #f6c343;
}

.page-title {
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  opacity: 1;
  color: #171719;
  margin-top: -40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Lato", serif;
}

.header-full .page-title {
  margin-top: 0px;
}

.page-header-infos {
  align-items: center;
}

.page-title h2 {
  text-align: left;
  font-family: "Lato", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0;
  color: #171719;
  opacity: 1;
}

.page-title p {
  font-family: "Lato", serif;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0;
  color: #525059;
  opacity: 1;
  margin: 0px;
}

.page-title .box-btn {
  text-align: right;
}

.page-title .btn {
  width: 100% !important;
  font-size: 15px;
  width: auto;
  margin-top: 0.25rem;
}

.page-title .btn-primary.c3.btn-dashed {
  border-style: dashed !important;
}

.border-primary-subtitle {
  text-align: center;
  font: Light 11px/13px Roboto;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  letter-spacing: 0;
  color: #3f25a8;
  opacity: 1;
}

.border-primary {
  border: 2px solid #3f25a8 !important;
  border-radius: 8px;
  opacity: 1;
  padding: 6px;
  text-align: center;
  color: #3f25a8;
}

.card-search {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
}

.card-search span {
  padding: 10px 0;
}

.card-search .icon-search {
  margin: 0 10px;
  width: 1rem;
  height: auto;
  color: #4f6c7c;
}

.input-custom {
  color: #6e84a3 !important;
  display: flex;
  margin-right: 3px;
  width: 95%;
  height: 48px;
  padding: 0 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: transparent;
  align-items: center;
}

.input-custom:focus {
  border: transparent !important;
  outline: none;
}

.input-custom:active {
  border: transparent;
}

.input-custom-input {
  background: white;
  width: calc(100% - 40px);
}

.drop-custom {
  width: 100%;
  position: relative;
}

.drop-custom.show {
  border-radius: 8px 8px 0 0;
}

.drop-custom .input-custom {
  color: #95aac9;
}

.drop-custom .dropdown-menu {
  position: absolute;
  width: calc(100% - 30px);
  margin: 0 15px;
}

.drop-custom.show .dropdown-menu {
  width: 100% !important;
  display: block;
  border: 1px solid #ececec;
  border-radius: 0.5rem;
  padding: 0;
  margin: 0.5rem 0 !important;
}

.drop-custom .dropdown-menu .dropdown-item {
  font-size: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #6e84a3;
  border-bottom: 1px solid #e9ecef;
}

.drop-custom .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0;
}

.dropdown-a {
  cursor: pointer;
}

.dropdown-a:hover {
  color: #ddd;
}

.dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-buttons .dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #6e84a3;
  display: block;
  font-weight: 400;
  padding: 0.375rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%;
}

.dropdown-buttons .dropdown-item:focus,
.dropdown-item:hover {
  color: #12263f;
  text-decoration: none;
  background-color: #e9ecef !important;
}

.dropdown-buttons svg {
  margin-right: 1rem;
  width: 1rem;
  max-height: 0.9rem;
}

#dropModal {
  cursor: pointer;
}

.dropdown-item {
  cursor: pointer;
}

.event-location {
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  color: #95aac9;
  opacity: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
}

.event-p-title {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0;
  color: #4f6c7c;
  opacity: 1;
  margin: 7px 0px;
  font-weight: 500;
}

.event-span {
  font-weight: 300;
}

.icon-button {
  display: inline-block;
  font-size: inherit;
  height: 1.5em;
  overflow: visible;
  vertical-align: -0.125em;
}

.eZPZaV,
.doqUdF,
.kjhGqe,
.favqoi,
.heAbaq,
.gibaWW {
  background: transparent;
}

.eaCdSY {
  border-radius: 8px;
}

.jzBROA {
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.favqoi {
  border-radius: 8px;
}

.subRow {
  margin-top: -1px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px;
}

.inLnHE {
  margin-top: 10px;
  border-radius: 8px 8px 0 0;
}

.heAbaq {
  color: #495057;
}

/* CORRIGIR CLASSES */
.jwPGvH {
  margin-top: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: 10px solid #4f6c7c;
  border-radius: 8px;
  transition: all 0.3s;
}

.jwPGvH:hover {
  border-left-color: #8f44dc;
}

.jwPGvH-color {
  margin-top: 10px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-left: 10px solid #8f44dc;
  border-radius: 8px;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  color: #4f6c7c;
  background-color: #ffffff;
  min-height: 48px;
}

.jwPGvH-color:not(:last-of-type) {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: transparent;
}

.gibaWW {
  color: #7d878a;
}

.qCZjB {
  border-radius: 8px;
}

.qCZjB:focus:not(:disabled) {
  background-color: transparent;
}

.text-small,
.text-small span {
  font-size: 0.75rem !important;
}

.select-search {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.select-search.is-disabled {
  opacity: 0.5;
}

.select-search__value {
  position: relative;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 7px);
  right: 19px;
  width: 9px;
  height: 9px;
  transform: rotate(45deg);
  border-right: 1px solid #6e84a3;
  border-bottom: 1px solid #6e84a3;
  pointer-events: none;
}

.select-search__input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #12263f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search__input[value=""] {
  color: greenyellow !important;
}

.select-search__input:hover {
  border-color: #00a5e3;
}

.select-search__input:focus {
  border-color: #00a5e3 !important;
  box-shadow: inset 0 1px 1px rgb(241 38 38 / 8%), 0 0 8px rgb(0 162 255 / 47%) !important;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  height: 180px;
  overflow: auto;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__options {
  list-style: none;
}

.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

.select-search__option {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  color: #5b7190;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 0.9rem;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  line-height: 1rem;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  border-color: #5b7190;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #5b7190;
  color: white;
}

.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-label::before {
  background-color: #f3f6f9;
  border: 1px solid #c9cdd0;
}

.custom-switch .custom-control-label::after {
  background-color: #fff;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  color: #fff;
  border-color: #8f44dc;
  background-color: #a26fd6;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.footer-equip {
  position: sticky;
  bottom: 0px;
  height: 60px;
  width: 100%;
  background-color: #4f6c7c;
  padding-top: 10px;
  overflow: hidden;
}

.footer-equip .row {
  margin-left: 0;
  margin-right: 0;
}

.footer-equip .select-search__select {
  top: auto !important;
  bottom: 60px !important;
}

@media screen and (max-width: 1920px) {
  .lot-value {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 1440px) {
  .lot-value {
    font-size: 15px !important;
  }
}

.border-bottom {
  border-bottom: 1px solid #4f6c7c !important;
  opacity: 1;
}

/* BASCIS */
.box-button {
  text-align: right;
  padding-bottom: 40px;
}

.box-button button {
  border-radius: 8px;
}

/* FORMS */
.card-img-upload {
  border: 2px dashed #edf2f9;
}

.upload-desc {
  margin-top: 5px;
  display: block;
  color: #95aac9;
}

.card-img-upload .input-group-text svg {
  margin-right: 10px;
}

.card-img-upload label {
  overflow: hidden;
  padding: 10px 75px 0px 10px;
  font-size: 12px;
  height: 38px;
  white-space: nowrap;
}

.card-img-upload label::after {
  font-size: 14px;
  height: 36px;
}

.input-radius-left {
  border-radius: 0.375rem 0 0 0.375rem;
}

.input-radius-right {
  border-radius: 0 0.375rem 0.375rem 0;
}

.img-uploaded {
  border-radius: 0.375rem !important;
  width: 64px;
  height: 64px;
  background-size: cover;
  float: right;
}

.banner-uploaded {
  border-radius: 0.375rem !important;
  width: 128px;
  height: 64px;
  background-size: cover;
  float: right;
}

.input-group-text {
  background-color: #f3f6f9;
  font-size: 1rem;
}

/* COMPONENTS */
.cards,
.card-default {
  border-radius: 8px;
  opacity: 1;
  padding: 3px;
  min-height: 85px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.card-default {
  background-color: #6e84a3;
}

.card-span {
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  letter-spacing: 0;
  color: #ffffff;
  opacity: 1;
}

.card-subspan {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #e9f2f2;
  opacity: 1;
  margin-top: -20px;
}

.card-row {
  position: relative;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* transition: 0.3s; */
}

.card-row:hover {
  background: #edf2f9;
}

.card-row-main {
  margin-top: 0.25rem;
}

.card-row-main,
.card-row .card-row-infos {
  display: flex;
  align-items: center;
}

.card-row-name {
  margin-left: 1rem;
}

.btn-table {
  cursor: pointer;
  margin-left: 0.5rem;
  border: none;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 25px;
  font-size: 0.7rem;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 12px;
  border-radius: 50px;
  background-color: #95aac9 !important;
  color: #fff !important;
  box-shadow: 0px 3px 6px #2a54663f;
}

@media screen and (max-width: 768px) {
  .pagetype-tickets {
    margin-bottom: 80px;
  }
}

.btn-table:hover {
  background-color: #183153 !important;
}

.icon-table {
  background: transparent;
  cursor: pointer;
  margin: 0 0.5rem;
  border: none;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  min-width: 25px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  font-size: 12px;
}

.icon-table svg {
  color: #2c7be5;
  height: 1.25rem !important;
  width: 1.25rem !important;
}

.icon-table:hover svg {
  color: #95aac9;
}

.mobile-buttons .btn-table span {
  display: none;
}

.mobile-buttons .btn-table:last-child {
  border-bottom: 0;
}

.card-row .mobile-buttons {
  position: absolute;
  align-items: center;
  display: flex;
  border-radius: 6px;
  justify-content: right;
  position: absolute;
  right: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1000;
  padding: 0.5rem 0;
}

.mobile-buttons .toogle-button {
  justify-content: space-between;
}

.event-online svg {
  color: #00d97e;
}

.event-offline svg {
  color: #e63757;
}

@media screen and (max-width: 415px) {
  .card-row-name {
    max-width: 310px;
    float: left;
  }
}

@media screen and (max-width: 375px) {
  .card-row-name {
    max-width: 280px !important;
  }
}

@media screen and (max-width: 360px) {
  .card-row-name {
    max-width: 250px !important;
  }
}

@media screen and (max-width: 320px) {
  .card-row-name {
    max-width: 220px !important;
  }
}

@media screen and (max-width: 768px) {

  /* .card-search {
    margin-top: 10px;
  } */
  .form-control {
    margin-bottom: 5px;
  }

  .page-title {
    height: auto !important;
  }

  /* .page-header-infos{
    justify-content: center;
    flex-direction: column;
  } */
  .page-title h2 {
    text-align: center !important;
    padding-left: 0px !important;
    font-size: 1rem;
  }

  .page-title p {
    text-align: center !important;
    padding-left: 0px !important;
    display: none;
  }

  .page-title .box-btn {
    text-align: center;
  }

  .page-title .btn {
    padding: 0.375rem 1.5rem;
    font-size: 0.8rem;
    width: auto;
  }

  .card-recipes {
    padding-left: 10px;
    padding-top: 5px;
  }

  .mt-mobile {
    margin-top: 20px;
  }

  .hidecallendar {
    display: none;
  }

  .content {
    padding: 10px;
  }

  .nav-link {
    display: block;
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    font-weight: normal;
  }

  .event-name {
    font-size: 18px !important;
    text-align: center;
  }

  .hidden-mobile {
    display: none;
  }
}

.icon-circle {
  margin-right: 0.8rem;
  float: left;
  font-size: 0.8rem;
  border-radius: 50px;
  border: 2px solid white;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle-sm {
  margin-right: 0.6rem;
  float: left;
  font-size: 0.6rem;
  border-radius: 50px;
  border: 2px solid white;
  width: 20px;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tips,
.tips-component {
  margin-left: 0.25rem;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.tips svg,
.tips-component svg {
  color: #95aac9;
}

.page-title .tips svg,
.page-title .tips-component svg {
  color: white;
}

.tips .tips-content,
.tips-component .tips-content,
.tips-component .tip-trust-level {
  visibility: hidden;
  width: 240px;
  background-color: #12263f;
  color: #95aac9;
  text-align: left;
  border-radius: 6px;
  padding: 0.8rem 0.95rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -120px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.75rem;
  font-weight: normal;
  white-space: normal;
}

.tips .tips-content a,
.tips-component .tips-content a,
.tips-component .tip-trust-level a {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

.tips .tips-header,
.tips-component .tips-header {
  font-weight: 400;
  color: #fff;
  font-size: 1rem;
}

.chevron-padding {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAiCAYAAAA+stv/AAAAAXNSR0IArs4c6QAAAARjSUNQAQ0AAZxpOzIAAACWSURBVFiF7dIxCsMwDAXQry4mm7Y4m3uDXiEn9xV6g3qrcwplkSGEDHEolMB/oyykj22AiIjoz6SneRynDwCI2FxrLduzGGMykwwAy/J9np356AngkplkVdVWUFX15al3WFcAEZsBFAAphCGrC2Foy4v3nJ/Zm3hz1QnA28uvtnz/ND8PcBACV5dfDrALcfgpiYiI6DZWwCAz9Q3F6KIAAAAASUVORK5CYII=);
  background-position: 100%;
  background-repeat: no-repeat;
  padding: 0.5rem;
}

.tips .tips-content::after,
.tips-component .tips-content::after,
.tips-component .tip-trust-level::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #12263f transparent transparent transparent;
}

.tips:hover .tips-content,
.tips-component:hover .tips-content,
.tips-component:hover .tip-trust-level {
  visibility: visible;
  opacity: 1;
  z-index: 100000;
}

.tooltip-custom {
  color: #fff !important;
  background-color: #183153 !important;
  z-index: 10000 !important;
}

.tooltip-custom.place-top::after,
.tooltip-custom.place-bottom::after {
  border-color: #183153 transparent !important;
}

.tooltip-custom.place-right::after,
.yourClass.place-left::after {
  border-color: transparent #183153 !important;
}

.jodit-workplace p {
  font-weight: unset !important;
  font-size: 1rem !important;
}

.jodit-workplace p a {
  cursor: pointer !important;
  color: #0050c3 !important;
}

.jodit,
.jodit *,
.jodit-container,
.jodit-container * {
  font-size: 1rem !important;
}

.icon-rotate {
  transform: rotate(-45deg);
}

/* MODAIS */
.side-modal {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 10000;
  background-color: white !important;
  transition: 0.5s;
}

.side-modal2 {
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  position: fixed;
  overflow-y: auto;
  background-color: white !important;
  transition: 0.5s;
}

@media only screen and (max-width: 968px) {
  .side-modal2 {
    width: 100%;
  }
}

.middle-modal {
  width: 50%;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: auto;
  z-index: 10000;
  background-color: white !important;
  transition: 0.5s;
}

.background-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 10000;
  background-color: rgba(0 0 0, 0.5);
  transition: 0.5s;
}

.modal-hidden {
  right: -50% !important;
}

.modal-show {
  right: 0% !important;
}

.left-modal-hidden {
  left: -50% !important;
}

.left-modal-show {
  left: 0% !important;
}

.left-side-modal {
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow-y: auto;
  z-index: 50000;
  background-color: white;
  transition: 0.5s;
}

.left-side-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-side-modal .card-header h4 {
  font-weight: bold;
}

.side-modal-visible {
  display: initial;
}

.side-modal.side-modal-hidden,
.side-modal.hide {
  right: -50%;
}

.side-modal .card-body {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.side-modal .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.side-modal .card-header h4 {
  font-weight: bold;
}

.side-modal .modal-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.side-modal .modal-footer2 {
  position: relative;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.side-modal .flpzgB {
  display: none !important;
}

@media screen and (max-width: 992px) {
  .side-modal {
    width: 100%;
    height: 100%;
    right: 0;
    border: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .side-modal.side-modal-hidden {
    right: -100% !important;
  }

  .modal-hidden {
    right: -100% !important;
  }
}

/* AJUSTES GERAIS */
.__react_component_tooltip {
  opacity: 0 !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* AJUSTES GRID */
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

/* AJUSTES TABELAS */
#dataTable-body .dataTable-td:first-child {
  padding-left: 10px;
}

.dataTable-thead .dataTable-th:first-child {
  padding-left: 15px;
}

.dataTable-th,
.rdt_TableHeadRow .rdt_TableCol_Sortable {
  color: #95aac9;
}

.card .tips {
  margin-left: 0.5rem;
}

.card .rdt_TableHead .rdt_TableCol:last-child,
.card .rdt_TableRow .rdt_TableCell:last-child {
  justify-content: flex-end;
}

.card .rdt_TableRow .rdt_TableCell:last-child div {
  padding-right: 15px;
}

.card .rdt_TableHead {
  font-size: 0.625rem !important;
  background-color: #f9fbfd !important;
}

.card .rdt_TableHead .rdt_TableHeadRow {
  border-radius: 0px;
  border: 0px;
  min-height: 48px;
}

.card .rdt_TableHead .rdt_TableCol .rdt_TableCol_Sortable {
  color: #95aac9 !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.7rem;
}

.card .rdt_TableHead .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  justify-content: flex-end;
}

.card .rdt_TableBody .rdt_TableRow {
  border-top: 1px solid #edf2f9 !important;
  border-bottom: 0px !important;
  border-left: 0px;
  margin-top: 0px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
}

.card .rdt_TableBody .rdt_TableRow:hover {
  background-color: #f9fbfd !important;
}

.card .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child {
  border-left: 10px solid #edf2f9 !important;
}

.card .rdt_TableBody .rdt_TableRow.row-active .rdt_TableCell:first-child {
  border-left-color: #8f44dc !important;
}

.card .rdt_TableRow .rdt_TableCell:last-child span {
  padding-right: 15px;
  justify-content: flex-end;
}

.card .rdt_TableRow .rdt_TableCell {
  color: #12263f;
}

.rdt_TableRow .rdt_TableCell:last-child,
.rdt_TableHeadRow .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  justify-content: flex-end;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

.table-sm {
  font-size: 0.8125rem;
}

.table {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #12263f;
}

.table thead {
  border-top: 1px solid #edf2f9;
  border-bottom: 1px solid #edf2f9;
  background-color: #f9fbfd !important;
  color: #95aac9 !important;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.08em;
}

.table thead th {
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: bold;
  letter-spacing: 0.08em;
  color: #95aac9;
  border: none;
}

.table.table-bordered thead th {
  border: 1px solid #dee2e6;
}

.table .thead-light {
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}

.table .thead-light th {
  color: #6e84a3 !important;
  border: none !important;
}

.table .thead-light tr:hover {
  color: #e2e6ed !important;
}

.table-sm td,
.table-sm th {
  padding: 1rem;
}

/* CUSTOM */
.alert {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.alert-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5;
}

.alert-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3;
}

.alert-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e;
}

.alert-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343;
}

.alert-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757;
}

.alert-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}

.alert-light {
  color: #283e59;
  background-color: #edf2f9;
  border-color: #edf2f9;
}

.alert-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f;
}

.badge {
  display: inline-block;
  padding: 0.5em 1.4em;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pill {
  padding-right: 0.8em !important;
  padding-left: 0.8em !important;
  border-radius: 10rem !important;
}

.badge.rounded-pill {
  padding: 0.33em 0.6em !important;
}

.badge.bg-danger {
  color: #fff !important;
  background-color: #e63757 !important;
}

.badge.bg-success {
  color: #fff !important;
  background-color: #00d97e !important;
}

.badge.bg-primary {
  color: #fff !important;
  background-color: #2c7be5 !important;
}

.badge.bg-info {
  color: #fff !important;
  background-color: #39afd1 !important;
}

.badge.bg-primary-soft {
  color: #2c7be5 !important;
  background-color: #d5e5fa !important;
}

.badge.bg-secondary-soft {
  color: #6e84a3 !important;
  background-color: #e2e6ed !important;
}

.badge.bg-success-soft {
  color: #00d97e;
  background-color: #ccf7e5;
}

.badge.bg-info-soft {
  color: #39afd1 !important;
  background-color: #d7eff6 !important;
}

.badge.bg-warning-soft {
  color: #f6c343 !important;
  background-color: #fdf3d9 !important;
}

.badge.bg-danger-soft {
  color: #e63757 !important;
  background-color: #fad7dd !important;
}

.badge.bg-light-soft {
  color: #edf2f9 !important;
  background-color: #fbfcfe !important;
}

.badge.bg-dark-soft {
  color: #12263f !important;
  background-color: #d0d4d9 !important;
}

.btn {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 0.375rem !important;
}

.btn-success {
  background-color: #00d97e;
  border-color: #00d97e;
}

.btn-success:hover {
  background-color: #00b86b;
  border-color: #00ae65;
}

.btn-danger {
  background-color: #e63757;
  border-color: #e63757;
}

.btn-danger:hover {
  background-color: #c42f4a;
  border-color: #b82c46;
}

.btn-dark {
  background-color: #12263f !important;
  border-color: #12263f !important;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus,
.btn-dark:hover {
  background-color: #0f2036 !important;
  border-color: #0e1e32 !important;
}

.btn-white {
  color: #283e59;
  background-color: #fff;
  border-color: #e3ebf6;
}

.btn-white.active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}

.btn-outline-success {
  border-color: #00d97e;
  color: #00d97e;
}

.btn-outline-success:hover {
  background-color: #00d97e;
  border-color: #00d97e;
  color: #fff;
}

.btn-outline-secondary {
  color: #ced4da;
  background-color: transparent;
  background-image: none;
  border: 2px solid #ced4da !important;
  width: 100%;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
}

.btn-outline-gray {
  color: #6e84a3;
  background-color: transparent;
  background-image: none;
  border: 2px solid #6e84a3 !important;
  width: 100%;
  font-weight: bold;
}

.btn-outline-gray:hover,
.btn-outline-gray:active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.btn-outline-gray:not(:disabled):not(.disabled):active,
.show>.btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
}

.btn-primary {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff;
}

.btn-primary:hover {
  background-color: #2569c3;
  border-color: #2362b7;
}

.btn-primary:disabled {
  background: #600ea3;
  border: 1px solid #600ea3;
}

.btn-secondary {
  color: #fff !important;
  background-color: #6e84a3 !important;
  border-color: #6e84a3 !important;
  width: 100%;
}

.btn-secondary:hover {
  background-color: #5b7190 !important;
  border-color: #566b88 !important;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.text-red {
  color: #e63757 !important;
}

.text-green {
  color: #00d97e !important;
}

.event-state td:first-child {
  border-left-width: 10px;
  border-left-style: solid;
}

.event-primary td:first-child {
  border-left-color: #2c7be5;
}

.event-secondary td:first-child {
  border-left-color: #6e84a3;
}

.event-success td:first-child {
  border-left-color: #00d97e;
}

.event-warning td:first-child {
  border-left-color: #f6c343;
}

.event-danger td:first-child {
  border-left-color: #e63757;
}

.event-info td:first-child {
  border-left-color: #39afd1;
}

.event-light td:first-child {
  border-left-color: #edf2f9;
}

.event-dark td:first-child {
  border-left-color: #12263f;
}

.close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 600;
  line-height: 1;
  color: #95aac9;
  text-shadow: none;
  opacity: 0.5;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
  color: #95aac9;
  border: 0;
}

button:focus {
  outline: none !important;
}

/* RULES */
.dev-feature,
.dev-feature.btn {
  border: 2px solid red !important;
  display: none;
}

.blursystem {
  filter: blur(3px);
}

.span-default {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  color: #7c7c7c;
  font-weight: bold;
  transition: 0.5s all;
  opacity: 1;
}

.span-active {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: bold;
  color: #00a5e3;
  transition: 0.5s all;
  opacity: 1;
}

.span-white {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  font-weight: bold;
  color: #fff;
  transition: 0.5s all;
  opacity: 1;
}

.span-white-number {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  color: #fff;
  transition: 0.5s all;
  opacity: 1;
}

.footer-page {
  bottom: 0px;
  height: 60px;
  width: 100%;
  background-color: #4f6c7c;
  padding-top: 10px;
  position: fixed;
}

.footer-page .row {
  margin-left: 0;
  margin-right: 0;
}

#select-stock .select-search {
  margin-bottom: 0px !important;
}

body::-webkit-scrollbar {
  height: 5px;
  overflow: overlay;
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #dc44bb;
  border-radius: 20px;
  border: 3px solid #dc44bb;
}

.tabela::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tabela::-webkit-scrollbar-track {
  background: #f9fbfd;
}

.tabela::-webkit-scrollbar-thumb {
  background-color: #8f44dc;
  border-radius: 10px;
  border: 3px solid #8f44dc;
}

.tabela::-webkit-scrollbar-button:single-button {
  background-color: #f9fbfd;
  display: block;
  border-style: solid;
  height: 13px;
  width: 16px;
}

.sector::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.sector::-webkit-scrollbar-track {
  background: transparent;
}

.sector::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 10px;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-width: 5px 14px 5px 0;
  border-color: transparent #4f6c7c transparent transparent;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent #8f44dc transparent transparent;
}

.tabela::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-width: 5px 0 5px 14px;
  border-color: transparent transparent transparent #4f6c7c;
}

.tabela::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: transparent transparent transparent #8f44dc;
}

.side-modal::-webkit-scrollbar {
  width: 10px;
}

.side-modal::-webkit-scrollbar-track {
  background: #fff;
}

.side-modal::-webkit-scrollbar-thumb {
  background-color: #600ea3;
  border-radius: 20px;
  border: 3px solid #600ea3;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-label {
  font-weight: bold;
  color: #4f6c7c;
  font-size: 12px;
  margin-bottom: -2px;
  margin-top: 10px;
}

.remove {
  color: transparent;
}

.category-counter {
  font-weight: bold;
  color: #95aac9;
  font-size: 13px;
  margin-bottom: -2px;
  margin-top: 10px;
}

.side-modal label {
  font-weight: bold;
  color: #4f6c7c;
  font-size: 12px;
  margin-bottom: -2px;
  margin-top: 10px;
  margin-bottom: 0.5rem;
}

.side-modal .form-check-label {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  border-radius: 0.2rem !important;
}

.side-modal .special-control {
  background-color: #e5eff6 !important;
  border-color: #e5eff6 !important;
  font-weight: bold !important;
  color: #506d7c !important;
}

.side-modal .special-control option {
  font-weight: bold !important;
  color: #506d7c !important;
}

.filter-button {
  color: rgb(68, 86, 154);
  border: 2px solid rgb(68, 86, 154);
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}

#filter-button-sales {
  margin-top: 2px;
  padding-top: 7px;
  padding-bottom: 6px;
}

#cleanFilters {
  margin-top: 2px;
  padding-top: 7px;
  padding-bottom: 6px;
  color: rgba(231, 55, 55, 0.973);
  border-color: rgba(231, 55, 55, 0.973);
}

#cleanFilters:hover {
  background-color: rgb(243, 209, 209);
}

.filter-button:hover {
  border: 2px solid rgba(68, 86, 154, 0.8);
  background-color: rgba(68, 86, 154, 0.3);
}

.side-modal .custom-file-label {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 16px !important;
}

.card .favqoi {
  border-left: 10px solid #edf2f9 !important;
}

.custom-input-option,
.custom-input-checkbox,
.custom-input-checkbox-disabled {
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  color: #fff;
  background-color: #6e84a3;
  transition: all 0.5s;
}

.recycle-bin {
  width: 24px;
  height: 24px;
}

.trash-icon:hover img {
  filter: brightness(10);
}

.custom-input-option:hover,
.custom-input-checkbox:hover {
  background-color: #12263f;
}

.swal2-container, .swal2-center, .swal2-backdrop-show {
  z-index: 2000000 !important;
}

.custom-input-option.active,
.custom-input-checkbox.active,
.custom-input-checkbox-disabled.active {
  background-color: #2075f4;
}

.custom-input-option.active:hover {
  background-color: #125fd3;
}

.custom-input-checkbox,
.custom-input-checkbox-disabled {
  font-size: 0.75rem;
}

.custom-input-checkbox.active:hover {
  background-color: #1366e2;
}

.ReactModal__Content {
  transform: translate(100%, 0);
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--after-open {
  transform: translate(0, 0);
  background-color: transparent;
  transition: all 500ms ease-in-out;
}

.ReactModal__Content--before-close {
  transform: translate(100%, 0);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  background-color: transparent;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: all 500ms ease-in-out;
}

.fadeIn {
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.infiteSpining {
  animation: infinite-spinning 1s infinite;
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.red-button {
  cursor: pointer;
  font-size: 28px;
  color: #e30f00;
}

.red-button:hover {
  color: #e30f00af;
}

.red-button.disabled {
  color: #707070;
  cursor: default !important;
}

@media (max-width: 768px) {
  .category-counter {
    display: none;
  }
}
/* COLORS */
div.c1,
button.c1,
nav.c1,
.btn.c1,
span.c1 {
  background-color: #00a5e3 !important;
  color: white;
}

.btn.c1:hover {
  background-color: #0061aa !important;
}

div.c2,
button.c2,
nav.c2,
.btn.c2,
span.c2 {
  background-color: #8f44dc !important;
  color: white;
}

div.b2 {
  border-color: #8f44dc !important;
}

.btn.c2:hover {
  background-color: #7228bf !important;
}

div.c3,
button.c3,
nav.c3,
.btn.c3,
span.c3 {
  background-color: #600ea3 !important;
  color: white;
}

.btn.c3:hover {
  background-color: #261d54 !important;
}

.page-title.c2 {
  background: #183153 !important;
}

.page-title .btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px !important;
}

.page-title .btn-primary.c3:hover {
  background: #4f6c7c !important;
  border-color: #4f6c7c !important;
  color: white !important;
}

.outline.btn.btn-primary.c3 {
  background: transparent !important;
  border: 2px solid white !important;
  border-radius: 50px;
}

.outline.btn.btn-primary.c3:hover {
  background: #95aac9 !important;
  border-color: #95aac9 !important;
  color: white !important;
}

button.btn-outline-primary {
  background-color: transparent !important;
  border: 1px solid;
  color: #2c7be5;
  border-color: #2c7be5;
}

button.btn-outline-primary:hover {
  color: #fff;
  background-color: #2c7be5 !important;
  border-color: #2c7be5 !important;
}

/* GENERAL */
#sidebar .nav-active {
  background: transparent linear-gradient(270deg, #0050c3 0%, #0050c3 100%) 0% 0% no-repeat padding-box;
  border-left: solid 2px #19f8fc;
}

.sidebarIcon {
  color: #00a7e5;
}

.card-one {
  background: #3f25a8 0% 0% no-repeat padding-box;
}

.card-grey {
  background: #61789a 0% 0% no-repeat padding-box;
}

.card-dark-purple {
  background: #4b1db5 0% 0% no-repeat padding-box;
}

.card-two {
  background: #7244dc 0% 0% no-repeat padding-box;
}

.card-three {
  background: #2075f4 0% 0% no-repeat padding-box;
}

.card-four {
  background: #00a7e1 0% 0% no-repeat padding-box;
}

.card-five {
  background: #d9277a 0% 0% no-repeat padding-box;
}

/* LOGIN */
#page-login,
#page-login .row {
  height: 100% !important;
}

.bg-cover {
  background: #600ea3;
}

/*LOADING*/
#loading-border {
  border-color: #20c4f4;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
    color: #600ea3;
  }

  50% {
    transform: rotate(180deg);
    color: #20c4f4;
  }

  100% {
    transform: rotate(359deg);
    color: #600ea3;
  }
}

#loading-icon span {
  background-color: #20c4f4;
}

#loading p:nth-child(2) {
  color: #20c4f4;
}
