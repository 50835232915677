#bottom-bar {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20000;
  background-color: #183153;
  display: none;
}
#bottom-bar .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#bottom-bar .container span {
  font-size: 0.6rem;
  font-weight: 300;
  margin-top: 0.2rem;
}
#bottom-bar .bottom-bar-icon {
  width: 40px;
  height: 40px;
  margin: 0.65rem;
  font-size: 1rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
#bottom-bar .bottom-bar-mainicon {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  margin-top: -20px;
  font-size: 1.5rem;
  color: white;
  background-color: #00a7e1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.buttonWhatsapp {
  background-color: transparent;
  color: #fff;
  border: 1px solid transparent;
}

@media screen and (max-width: 768px) {
  #bottom-bar {
    display: block;
  }
}
